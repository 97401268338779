import React, { useState, useEffect } from 'react'
import logo from '../../Images/home4/SiteLogo.png'
import Axios from 'axios'
import ReactDOM from 'react-dom'
import { json, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CurrencyConverter } from '../GlobalData/GlobalData'
import { Drawer, Nav } from 'rsuite'
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import { CurrencyRates, AllCurrencyRates } from '../../Redux/Actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faSignIn,
  faBars,
  faTimes,
  faChevronDown,
  faCross,
  faClose,
  faAngleRight,
  faArrowDown,
  faAngleDown,
  faMosque,
  faKaaba,
  faDownload,
  faGlobe,
  faBell
} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18n from '../../i18n'
import LanguageSwitcher from '../Activity/TranslationTrigger'
import { countryListLocal } from '../Data/CountryList'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'
function Navbar ({ goLogout }) {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigation = useNavigate()
  var token = Hotelapitoken()
  var endpoint = ApiEndPoint()
  const [modalOpen, setModalOpen] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [openWithHeader, setOpenWithHeader] = React.useState(false)
  const [countryList, setCountryList] = useState([])
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [userCountry, setUserCountry] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [dropdownCat, setDropdownCat] = useState([])
  const [isDropdownOpen2, setDropdownOpen] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState('SAR')
  const [currencyFlag, setCurrencyFlag] = useState(
    'https://flagcdn.com/w320/sa.png'
  )
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenc, setIsOpenc] = useState(false)
  const [selectedOption, setSelectedOption] = useState('Open this select menu')

  const options = [
    { value: 'SAR', label: 'SAR', imgSrc: 'https://flagcdn.com/w320/sa.png' },
    { value: 'PKR', label: 'PKR', imgSrc: 'https://flagcdn.com/w320/pk.png' }
  ]

  const handleOptionClick = option => {
    setIsOpenc(false)
    setSelectedCurrency(option.label)
    setCurrencyFlag(option.imgSrc)
    CurrencyCalucaltion(option.label)
    localStorage.setItem('selectedCurrency', option.label)
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  // This function put query that helps to
  // change the language

  // const toggleDropdown = () => {
  //   setDropdownOpen(!isDropdownOpen2)
  // }

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen)
  }
  const buttonIconClass = isMobileNavOpen
    ? 'navbar-toggler-icon-cross'
    : 'navbar-toggler-icon'
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  const toggleModal1 = () => {
    setOpen(false)
    setModalOpen(!modalOpen)
  }
  useEffect(() => {
    GetCatagories()
    fetchData()
    AllCurrency()
    localStorage.setItem('selectedCurrencyNew', selectedCurrency)
    const storedCurrency = localStorage.getItem('selectedCurrency')
    const matchingOption = options.find(item => item.value === storedCurrency)
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency)
      setCurrencyFlag(matchingOption.imgSrc)
    } else {
      localStorage.setItem('selectedCurrency', 'SAR')
    }
  }, [])

  const SearchValue = e => {
    var filteredList = countryList.filter(item =>
      item.currency.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setUserCountry(filteredList)
    setSearchValue(e.target.value)
  }

  async function fetchData () {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    try {
      const response = await Axios.get(
        endpoint + '/api/countries/fetch',
        headers
      )
      setCountryList(response.data.countries)
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const handleCurrencyDivClick = (index, currency, name) => {
    toggleModal()
    setActiveIndex(index)
    setSelectedCurrency(currency)
    var countryflag = []
    countryflag = countryListLocal.item.filter(
      item => item.name.common === name
    )
    if (countryflag.length !== 0) {
      setCurrencyFlag(countryflag[0].flags.png)
    } else {
      setCurrencyFlag('')
    }
    CurrencyCalucaltion(currency)

    localStorage.setItem('selectedCurrency', currency)

    // Perform other calculations or actions here
  }

  const CurrencyCalucaltion = c => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + c, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        response.data.selectedcurrency = c
        dispatch(CurrencyRates(response.data))
        localStorage.setItem('checkapiCurr','True')
      })
      .catch(error => {
        localStorage.setItem('checkapiCurr',null)
        // Handle errors here
        console.error(error)
      })
  }

  const AllCurrency = () => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/SAR', // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        // Handle the response data here
        localStorage.setItem('SarCurrencyRates', JSON.stringify(response.data))
        dispatch(AllCurrencyRates(response.data))
        localStorage.setItem('checkapiCurr','True')
      })
      .catch(error => {
        localStorage.setItem('checkapiCurr',null)
        // Handle errors here
        console.error(error)
      })
  }

  const GetCatagories = async () => {
    var data = {
      token: token
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/get_all_catigories_list_apis_new',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.setItem(
        'Catogories',
        JSON.stringify(response.data.categories)
      )
      setDropdownCat(response.data.categories)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const FindPackageDetail = id => {
    toggleDropdown()
    navigation('/umrah_packages', { state: id })
  }
  const languages = [
    { value: 'en', text: 'English' },
    { value: 'ar-SA', text: 'Arabic' }
  ]
  const logout = () => {
    goLogout()
  }
  return (
    <>
      <Drawer
        style={{ zIndex: '9999', width: '100%' }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Waqar International</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div onClick={() => setOpen(false)} className='mobile-nav-new'>
            <NavLink to='/'>
              <h4>Home</h4>
            </NavLink>
          </div>
          <div onClick={() => setOpen(false)} className='mt-1 mobile-nav-new'>
            <NavLink to='/about-us'>
              <h4>About us</h4>
            </NavLink>
          </div>
          <div onClick={() => setOpen(false)} className='mt-1 mobile-nav-new'>
            <NavLink to='/contact-us'>
              <h4>Contact us</h4>
            </NavLink>
          </div>
          <div onClick={() => setOpen(false)} className='mt-1 mobile-nav-new'>
            <NavLink to='/customer_dashboard'>
              <h4>Agent Dashboard</h4>
            </NavLink>
          </div>
          <div className='mt-1 mobile-nav-new'>
            <button
              onClick={() => setIsOpenc(!isOpen)}
              style={{ width: '100%' }}
              className='btn mt-2 btn-success'
            >
              <img
                style={{ height: 'auto', width: '34px' }}
                className='p-1 me-2'
                src={currencyFlag}
              />
              {selectedCurrency}
            </button>
            <div className={`custom-select ${isOpenc ? 'open' : ''}`}>
              <div className='custom-options mt-1'>
                {options.map(option => (
                  <span
                    key={option.value}
                    className='custom-option'
                    onClick={() => handleOptionClick(option)}
                  >
                    <img src={option.imgSrc} alt={option.label} />{' '}
                    {option.label}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className='mt-1 mobile-nav-new'>
            <button
              onClick={logout}
              style={{ width: '100%' }}
              className='btn mt-2 btn-success'
            >
              Logout
            </button>
          </div>
          <div class='hotline-area top-button-effect d-flex  ps-2 mt-2  non'>
            <div class='icon'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='28'
                height='28'
                viewBox='0 0 28 28'
              >
                <path d='M27.2653 21.5995L21.598 17.8201C20.8788 17.3443 19.9147 17.5009 19.383 18.1798L17.7322 20.3024C17.6296 20.4377 17.4816 20.5314 17.3154 20.5664C17.1492 20.6014 16.9759 20.5752 16.8275 20.4928L16.5134 20.3196C15.4725 19.7522 14.1772 19.0458 11.5675 16.4352C8.95784 13.8246 8.25001 12.5284 7.6826 11.4893L7.51042 11.1753C7.42683 11.0269 7.39968 10.8532 7.43398 10.6864C7.46827 10.5195 7.56169 10.3707 7.69704 10.2673L9.81816 8.61693C10.4968 8.08517 10.6536 7.1214 10.1784 6.40198L6.39895 0.734676C5.91192 0.00208106 4.9348 -0.21784 4.18082 0.235398L1.81096 1.65898C1.06634 2.09672 0.520053 2.80571 0.286612 3.63733C-0.56677 6.74673 0.0752209 12.1131 7.98033 20.0191C14.2687 26.307 18.9501 27.9979 22.1677 27.9979C22.9083 28.0011 23.6459 27.9048 24.3608 27.7115C25.1925 27.4783 25.9016 26.932 26.3391 26.1871L27.7641 23.8187C28.218 23.0645 27.9982 22.0868 27.2653 21.5995ZM26.9601 23.3399L25.5384 25.7098C25.2242 26.2474 24.7142 26.6427 24.1152 26.8128C21.2447 27.6009 16.2298 26.9482 8.64053 19.3589C1.0513 11.7697 0.398595 6.75515 1.18669 3.88421C1.35709 3.28446 1.75283 2.77385 2.2911 2.45921L4.66096 1.03749C4.98811 0.840645 5.41221 0.93606 5.62354 1.25397L7.67659 4.3363L9.39976 6.92078C9.60612 7.23283 9.53831 7.65108 9.24392 7.88199L7.1223 9.53232C6.47665 10.026 6.29227 10.9193 6.68979 11.6283L6.85826 11.9344C7.45459 13.0281 8.19599 14.3887 10.9027 17.095C13.6095 19.8012 14.9696 20.5427 16.0628 21.139L16.3694 21.3079C17.0783 21.7053 17.9716 21.521 18.4653 20.8753L20.1157 18.7537C20.3466 18.4595 20.7647 18.3918 21.0769 18.5979L26.7437 22.3773C27.0618 22.5885 27.1572 23.0128 26.9601 23.3399ZM15.8658 4.66809C20.2446 4.67296 23.7931 8.22149 23.798 12.6003C23.798 12.858 24.0069 13.0669 24.2646 13.0669C24.5223 13.0669 24.7312 12.858 24.7312 12.6003C24.7257 7.7063 20.7598 3.74029 15.8658 3.73494C15.6081 3.73494 15.3992 3.94381 15.3992 4.20151C15.3992 4.45922 15.6081 4.66809 15.8658 4.66809Z' />
                <path d='M15.865 7.46746C18.6983 7.4708 20.9943 9.76678 20.9976 12.6001C20.9976 12.7238 21.0468 12.8425 21.1343 12.93C21.2218 13.0175 21.3404 13.0666 21.4642 13.0666C21.5879 13.0666 21.7066 13.0175 21.7941 12.93C21.8816 12.8425 21.9308 12.7238 21.9308 12.6001C21.9269 9.2516 19.2134 6.53813 15.865 6.5343C15.6073 6.5343 15.3984 6.74318 15.3984 7.00088C15.3984 7.25859 15.6073 7.46746 15.865 7.46746Z' />
                <path d='M15.865 10.267C17.1528 10.2686 18.1964 11.3122 18.198 12.6C18.198 12.7238 18.2472 12.8424 18.3347 12.9299C18.4222 13.0174 18.5409 13.0666 18.6646 13.0666C18.7883 13.0666 18.907 13.0174 18.9945 12.9299C19.082 12.8424 19.1312 12.7238 19.1312 12.6C19.1291 10.797 17.668 9.33589 15.865 9.33386C15.6073 9.33386 15.3984 9.54274 15.3984 9.80044C15.3984 10.0581 15.6073 10.267 15.865 10.267Z' />
              </svg>
            </div>
            <div class='content'>
              <span>To More Inquiry</span>
              <h6>
                <a href='tel:+923215090400'>+92 321 5090400</a>
              </h6>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
      <header>
        <div className='container navbar-top'>
          <div className='row'>
            <div className='col-lg-2 col-md-2 navbar-img col-sm-12'>
              <div className=' d-flex justify-content-between align-items-center'>
                <img
                  src={logo}
                  style={{ width: '6.5em', height: 'auto' }}
                  alt='expedia logo'
                  height={70}
                />
                <h4 onClick={() => setOpen(true)} className='mobile-bar'>
                  <FontAwesomeIcon icon={faBars} />
                </h4>
              </div>
            </div>
            <div
              className='col-lg-10 col-md-10 col-sm-12'
              style={{ margin: 'auto' }}
            >
              <div className='navbar-top-right'>
                <div className='d-flex top-button-effect non'>
                  <NavLink to='/'>
                    <h5
                      className='non'
                      style={{ color: 'black', fontSize: '14px' }}
                    >
                      Home
                    </h5>{' '}
                  </NavLink>{' '}
                </div>
                {/* <div className='d-flex top-button-effect non'>  
                  <button className='btn btn-success non'> <h5 style={{color:'black', fontSize:'14px'}}> Activities</h5> </button>
                </div> */}
                <div className='d-flex top-button-effect non'>
                  <NavLink to='/about-us'>
                    {' '}
                    <h5
                      style={{ color: 'black', fontSize: '14px' }}
                      className='non'
                    >
                      {' '}
                      About us
                    </h5>{' '}
                  </NavLink>
                </div>
                <div className='d-flex top-button-effect non'>
                  <NavLink to='/contact-us'>
                    {' '}
                    <h5
                      style={{ color: 'black', fontSize: '14px' }}
                      className='non'
                    >
                      {' '}
                      Contact us
                    </h5>{' '}
                  </NavLink>
                </div>
                <div className='d-flex top-button-effect non'>
                  <a
                    class='nav-link dropdown-toggle non'
                    onClick={toggleDropdown}
                    href='#'
                    id='navbarDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    Agent Dashboard
                  </a>
                  <div
                    style={{ top: '4em' }}
                    className={`dropdown-menu ${isOpen ? 'show' : ''}`}
                    aria-labelledby='navbarDropdown'
                  >
                    <NavLink
                      onClick={toggleDropdown}
                      className='dropdown-item'
                      to='/customer_dashboard'
                    >
                      {' '}
                      Agent Dashboard
                    </NavLink>
                    <a class='dropdown-item' onClick={logout} href='#'>
                      Logout
                    </a>
                  </div>
                </div>
                <div className='d-flex top-button-effect non'>
                  {/* <button
                    style={{
                      background: 'green',
                      color: '#fff',
                      borderRadius: '5px'
                    }}
                    className='btn btn-success non'
                    onClick={toggleModal}
                  >
                    {' '}
                    <h5 style={{ fontSize: '14px' }}><img style={{height:'auto',width:'34px'}} className='p-1 me-2' src={currencyFlag}/>{selectedCurrency}</h5>{' '}
                  </button> */}
                  <div className='custom-select-wrapper non'>
                    <div className={`custom-select ${isOpenc ? 'open' : ''}`}>
                      <div
                        className='custom-select-trigger'
                        onClick={() => setIsOpenc(!isOpen)}
                      >
                        <img
                          style={{ height: 'auto', width: '34px' }}
                          className='p-1 me-2'
                          src={currencyFlag}
                        />{' '}
                        {selectedCurrency}
                      </div>
                      <div className='custom-options mt-1'>
                        {options.map(option => (
                          <span
                            key={option.value}
                            className='custom-option'
                            onClick={() => handleOptionClick(option)}
                          >
                            <img src={option.imgSrc} alt={option.label} />{' '}
                            {option.label}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/*********** Cuurency  Model ****************/}
      <Modal
        style={{ maxWidth: '90%' }}
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>Select Your Currency</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <p>
              Where applicable, prices will be converted to—and shown in—the
              currency you select. The currency you pay in may differ based on
              your reservation, and a service fee may also apply.
            </p>
            <div className='border-line mt-2 mb-2'></div>
            <div className='row'>
              <div className='d-flex justify-content-between'>
                <h5>All Currencies</h5>
                <input
                  type='text'
                  value={searchValue}
                  onChange={SearchValue}
                  placeholder='Search'
                />
              </div>

              {searchValue === ''
                ? // Render the full list when there is no search input
                  countryList.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(
                            index,
                            item.currency,
                            item.name
                          )
                        }
                      >
                        <p className='currency-item'>
                          {item.name} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))
                : // Render the filtered list when there is a search input
                  userCountry.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(
                            index,
                            item.currency,
                            item.name
                          )
                        }
                      >
                        <p className='currency-item'>
                          {item.name} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Navbar
