import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import moment from 'moment'
import logo from '../../Images/home4/SiteLogo.png'
import {
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import Loading from '../../Components/Loading/Loader'
function ActivityInvoicen () {
  const [isLoading, setIsLoading] = useState(false)
  const [childDetail, setChildDetail] = useState([])
  const [leadPassenger, setLeadPassenger] = useState([])
  const [tourData, setTourData] = useState([])
  const [AdditionalService, setAdditionalService] = useState([])
  const [cartTotalData, setCartTotalData] = useState([])
  const [InvoiceData, setInvoiceData] = useState([])

  var endpoint = ApiEndPoint()

  useEffect(() => {
    invoicedata();
  }, [])

  const invoicedata = async () => {
    var tkn = Hotelapitoken()
    setIsLoading(true);
    const currentURL = window.location.href
    const parts = currentURL.split('/')
    const lastPart = parts[parts.length - 1]
    var data = {
      token: tkn,
      booking_id: lastPart
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/invoice_data_react',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )

      setIsLoading(false)
      setCartTotalData(response.data?.cart_data[0])
      setTourData(response.data.inv_details)
      setInvoiceData(response.data.tour_data[0])
      setChildDetail(JSON.parse(response.data.inv_details.child_detail))
      setLeadPassenger(JSON.parse(response.data.inv_details.passenger_detail))
      if (response.data?.cart_data[0].Additional_services_names !== '') {
        setAdditionalService(
          JSON.parse(response.data?.cart_data[0].Additional_services_names)
        )
      }
    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Error:', error)
    }
  }
  return (
    <>
      {isLoading && <Loading />}
      <div class='container mt-4' style={{ color: '#000' }}>
        <div class='row mb-4'>
          <div class='col-md-2 col-lg-2 col-sm-2 text-center'>
            <img src={logo} height='90' />
          </div>
          <div class='col-md-8 col-sm-8 col-lg-8 text-center'>
            <h2>Waqar International Travel & Tours</h2>
            <h3>(Islamabad)</h3>
            <p>Office no 6 7 Shan plaza near Punjab college fazal e Haq road blue area Islamabad</p>
            <p>Phone: +92 321 5090400</p>
            <p>Email: info@waqarint.com</p>
          </div>
          <div class='col-md-2 col-lg-2 col-sm-2 text-end'>
            {/* <p>WAQAR45-BAKER</p>
            <p>SALEM</p> */}
          </div>
          <div className='col-lg-8 col-md-7 col-sm-7 mt-2'>
            <h6 class='mb-3'>Invoice No: {tourData.invoice_no}</h6>
          </div>
          <div className='col-lg-4 col-md-5 col-sm-5 text-end mt-2'>
            <h6>
              DATE CREATED: {moment(tourData.created_at).format('DD-MM-YYYY')}
            </h6>
          </div>
        </div>
        <div class='accommodation-details mt-3'>
          <h6>ACTIVITY INFORMATION</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Duration</th>
                  <th>Adults</th>
                  <th>Childs</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{cartTotalData.tour_name}</td>
                  <td>
                    {moment(cartTotalData.activity_select_date).format(
                      'DD-MM-YYYY'
                    )}
                  </td>
                  <td>{InvoiceData.duration} hours</td>
                  <td>{cartTotalData.adults}</td>
                  <td>{cartTotalData.childs}</td>
                  <td>
                    {cartTotalData.currency} {cartTotalData.price}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {AdditionalService.length !== 0 && (
          <div class='accommodation-details mt-3'>
            <h6>ADDITIONAL SERVICES</h6>
            <div class='mobile-table-scroll '>
              <table class='table table-bordered'>
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Name</th>
                    <th>Service Type</th>
                    <th>Persons</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {AdditionalService?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.service}</td>
                      <td>{item.service_type}</td>
                      <td>{item.person}</td>
                      <td>{item.total_price}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div class='accommodation-details mt-3'>
          <h6>PASSENGERS' INFORMATION</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Pilgrims Name</th>
                  <th>Email</th>
                  <th>DOB</th>
                  <th>Country</th>
                  <th>Contact No</th>
                </tr>
              </thead>
              <tbody>
                {leadPassenger.length > 0 &&
                  leadPassenger.map((item, index) => (
                    <tr key={index}>
                      <td>Passenger {index + 1}</td>
                      <td>
                        {item.lead_title + ' ' + item.name + ' ' + item.lname}
                      </td>
                      <td>{item.email}</td>
                      <td>{moment(item.date_of_birth).format('DD-MM-YYYY')}</td>
                      <td>{item.country}</td>
                      <td>{item.phone}</td>
                    </tr>
                  ))}
                {childDetail.length > 0 &&
                  childDetail.map((item, index) => (
                    <tr key={index}>
                      <td>Child {index + 1}</td>
                      <td>{item.passengerName + ' ' + item.lname}</td>
                      <td>N/A</td>
                      <td>{moment(item.date_of_birth).format('DD-MM-YYYY')}</td>
                      <td>{item.country}</td>
                      <td>N/A</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='text-center print-div-hide justify-content-center d-flex  ms-2'>
          <button
            onClick={() => window.print()}
            className='btn mb-3 select-styling search-btn1 set-page-ntm-width detail-view-btn btn-success'
          >
            Print Invoice
          </button>
        </div>
        <div className='row'>
          <div class=' col-lg-12 mt-3'>
            <p>MAKKAH OFFICE | SOHAIL NAWAZ SADOZAI | PHONE #: 058-3390811</p>
            <p>MAKKAH OFFICE | OWAIS BHUTTA | PHONE #: 059-8382599</p>
            <p>MADINA OFFICE | KOUSAR NADEEM | PHONE #: 059-1139698</p>
            <p>MADINA OFFICE | RIZWAN MED TRANSPORT | PHONE #: 0599104921</p>
            <p>
              JEDDAH AIRPORT OFFICE | TRANSPORT BY NAQAL ARABI AND WAFA AL MASSI
              | PHONE #: 05
            </p>
          </div>
          {/* <div class=' text-end col-lg-6 mt-3'>
          <h6>:نوٹ</h6>
          <p> براہ کرم اس بات کا خاص خیال رکھیں کہ روانگی سے کم از کم تین گھنٹے پہلے ائرپورٹ پہنچیں تاکہ آپ کی روانگی کے وقت آپ کو کسی قسم کی پریشانی کا سامنا نہ کرنا پڑے۔ علاوہ ازیں، تمام سفراء کو اطلاع دی جاتی ہے کہ وہ اپنے سفری دستاویزات اور شناختی کارڈز کو ہمراہ رکھیں۔</p>
        </div> */}
        </div>
      </div>
    </>
  )
}
export default ActivityInvoicen
