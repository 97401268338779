import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import moment from 'moment'
import logo from '../../Images/home4/SiteLogo.png'
import {
  ApiEndPoint,
  TransferCheckoutToken
} from '../../Components/GlobalData/GlobalData'
import Loading from '../../Components/Loading/Loader'
function TransferInvoicen () {
    var endpoint=ApiEndPoint();
    const [isLoading,setIsLoading]=useState(false);
    const [otherPassenger,setotherPassenger]=useState([])
    const [leadPassenger, setLeadPassenger] = useState([]);
    const [bookingInfo, setBookingInfo] = useState([]);
    const [TransferData, setTransferData] = useState([]);
    useEffect(()=>{
        invoicedata();
      },[]);

    const invoicedata=async()=>{
        var tkn=TransferCheckoutToken();
        setIsLoading(true);
        const currentURL = window.location.href;
        const parts = currentURL.split('/');
       const lastPart = parts[parts.length - 1];
       var data={
        'token':tkn,
        'invoice_no':lastPart
        }
       
        try {
            const response = await Axios.post(
              endpoint+'/api/transfer_invoice_react',
              data,
              {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  // Required for CORS support to work
                  'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
                  'Access-Control-Allow-Headers':
                    'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
                  // "Access-Control-Allow-Methods": "POST, OPTIONS"
                }
              }
      
            )
            setIsLoading(false);
            var other=JSON.parse(response.data.transfer_booking_data.other_passenger_data)
            setotherPassenger(other);
            setBookingInfo(response.data.transfer_booking_data);
            setLeadPassenger(JSON.parse(response.data.transfer_booking_data.lead_passenger_data));
            setTransferData(JSON.parse(response.data.transfer_booking_data.transfer_data));
          } catch (error) {
            // Handle errors here
            setIsLoading(false);
            console.error('Error:', error)
          }
      };
  return (
    <>
    {isLoading && (
      <Loading/>
    )}
      <div class='container mt-4' style={{ color: '#000' }}>
        <div class='row mb-4'>
          <div class='col-md-2 col-lg-2 col-sm-2 text-center'>
            <img src={logo} height='90' />
          </div>
          <div class='col-md-8 col-sm-8 col-lg-8 text-center'>
            <h2>Waqar International Travel & Tours</h2>
            <h3>(Islamabad)</h3>
            <p>Office no 6 7 Shan plaza near Punjab college fazal e Haq road blue area Islamabad</p>
            <p>Phone: +92 321 5090400</p>
            <p>Email: info@waqarint.com</p>
          </div>
          <div class='col-md-2 col-lg-2 col-sm-2 text-end'>
            {/* <p>WAQAR45-BAKER</p>
            <p>SALEM</p> */}
          </div>
          <div className='col-lg-8 col-md-7 col-sm-7 mt-2'>
            {bookingInfo.booking_status==='Confirmed' ? (
              <h6 class='mb-3'>
                Invoice No: {bookingInfo.invoice_no} | Status: <span style={{color:'#63ab45'}}>{bookingInfo.booking_status}</span>
              </h6>
            ):(
              <h6 class='mb-3'>
                Invoice No: {bookingInfo.invoice_no} | Status: {bookingInfo.booking_status}
              </h6>
            )}
          </div>
          <div className='col-lg-4 col-md-5 col-sm-5 text-end mt-2'>
            <h6>
              DATE CREATED:{' '}
              {moment(bookingInfo.created_at).format('DD-MM-YYYY')}
            </h6>
          </div>
        </div>
        {/* <div class='general-info mt-3'>
          <h6>GENERAL INFORMATION</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Total Pilgrims</th>
                  <th>Adults</th>
                  <th>Children</th>
                  <th>Total Room</th>
                  <th>Check in Date</th>
                  <th>Check out Date</th>
                  <th>Total Nights</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {Number(invoiceDetail.total_adults) +
                      Number(invoiceDetail.total_childs)}
                  </td>
                  <td>{invoiceDetail.total_adults}</td>
                  <td>{invoiceDetail.total_childs}</td>
                  <td>{invoiceDetail.total_rooms}</td>
                  <td>{moment(hoteldetail2.checkIn).format('DD-MM-YYYY')}</td>
                  <td> {moment(hoteldetail2.checkOut).format('DD-MM-YYYY')}</td>
                  <td>{daysBetween}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}

        <div class='accommodation-details mt-3'>
          <h6>TRANSFER INFORMATION</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Pickup</th>
                  <th>Dropof</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{TransferData.vehicle_Name}</td>
                  <td>{TransferData.transfer_type}</td>
                  <td>{moment(bookingInfo.departure_date).format('DD-MM-YYYY')}</td>
                  <td>{TransferData.pickup_City}</td>
                  <td>{TransferData.dropof_City}</td>
                  <td>
                    {bookingInfo.exchange_currency === 'SAR'
                        ?bookingInfo.exchange_currency 
                        : bookingInfo.currency}{' '}
                        {bookingInfo.exchange_currency === 'SAR'
                        ? bookingInfo.transfer_total_price_exchange
                        :  bookingInfo.transfer_price}
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* <div class='accommodation-details mt-3'>
          <h6>ROOM DETAILS</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Board Type</th>
                  <th>Room Quantity</th>
                  <th>Passenger</th>
                </tr>
              </thead>
              <tbody>
                {rooms.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td> {item.room_name}</td>
                    <td>{item.room_code}</td>
                    <td> {item.room_rates[0].room_board}</td>
                    <td>{item.room_rates[0].room_qty}</td>
                    <td>
                      {' '}
                      {item.room_rates[0].adults} Adults,{' '}
                      {item.room_rates[0].children} Children
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}
        <div class='accommodation-details mt-3'>
          <h6>PASSENGERS' INFORMATION</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Pilgrims Name</th>
                  <th>Email</th>
                  <th>DOB</th>
                  <th>Contact No</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lead Passenger</td>
                  <td>
                    {leadPassenger.lead_title +
                      ' ' +
                      leadPassenger.lead_first_name +
                      ' ' +
                      leadPassenger.lead_last_name}
                  </td>
                  <td>{leadPassenger.lead_email}</td>
                  <td>
                    {moment(leadPassenger.lead_date_of_birth).format(
                      'DD-MM-YYYY'
                    )}
                  </td>
                  <td>{leadPassenger.lead_phone}</td>
                </tr>
                {otherPassenger.length > 0 &&
                  otherPassenger.map((item, index) => (
                    <tr key={index}>
                      <td>Passenger {index + 2}</td>
                      <td>{item.title + ' ' + item.name}</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>{item.nationality}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div class='transport-details mt-3'>
          <h4>TRANSPORT DETAILS</h4>
          <table class='table table-bordered'>
            <thead>
              <tr>
                <th>Sector</th>
                <th>Transport</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>JED-MAK-MED-MED APT</td>
                <td>Ziarat: None</td>
              </tr>
              <tr>
                <td>JED-MAK</td>
                <td>BUS</td>
              </tr>
              <tr>
                <td>MAK-MED</td>
                <td>BUS</td>
              </tr>
              <tr>
                <td>MED-MED APT</td>
                <td>BUS</td>
              </tr>
            </tbody>
          </table>
        </div> */}

        <div className='text-center print-div-hide justify-content-center d-flex  ms-2'>
          <button
            onClick={() => window.print()}
            className='btn mb-3 select-styling search-btn1 set-page-ntm-width detail-view-btn btn-success'
          >
            Print Invoice
          </button>
        </div>
        <div className='row'>
        <div class=' col-lg-12 mt-3'>
          <p>MAKKAH OFFICE | SOHAIL NAWAZ SADOZAI | PHONE #: 058-3390811</p>
          <p>MAKKAH OFFICE | OWAIS BHUTTA | PHONE #: 059-8382599</p>
          <p>MADINA OFFICE | KOUSAR NADEEM | PHONE #: 059-1139698</p>
          <p>MADINA OFFICE | RIZWAN MED TRANSPORT | PHONE #: 0599104921</p>
          <p>
            JEDDAH AIRPORT OFFICE | TRANSPORT BY NAQAL ARABI AND WAFA AL MASSI |
            PHONE #: 05
          </p>
        </div>
        {/* <div class=' text-end col-lg-6 mt-3'>
          <h6>:نوٹ</h6>
          <p> براہ کرم اس بات کا خاص خیال رکھیں کہ روانگی سے کم از کم تین گھنٹے پہلے ائرپورٹ پہنچیں تاکہ آپ کی روانگی کے وقت آپ کو کسی قسم کی پریشانی کا سامنا نہ کرنا پڑے۔ علاوہ ازیں، تمام سفراء کو اطلاع دی جاتی ہے کہ وہ اپنے سفری دستاویزات اور شناختی کارڈز کو ہمراہ رکھیں۔</p>
        </div> */}
        </div>
      </div>
    </>
  )
}
export default TransferInvoicen
