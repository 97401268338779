import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style/Style.css';
import './Style/Mediaquries.css'
import 'wowjs/css/libs/animate.css';
import 'animate.css';
import logo from '../src/Images/whatsappchatlogo.png'
 import { BrowserRouter as Router, Route, Routes,Switch  } from "react-router-dom";
 import Home from './Pages/Home/Home';
import Contact from './Pages/Contactus/ContactusNew';
import About from './Pages/Aboutus/AboutusNew';
import Umrahpackages from './Pages/UmrahPackages/UmrahPackages';
import InvoicePage from './Pages/UmrahPackages/Invoicepage';
import ViewDeail from './Pages/ViewDetail/ViewDetail';
import SearchTour from './Pages/SearchTour/SearchTour';
import Hotels from './Pages/Hotels/Hotel';
import HotelDetail from './Pages/Hotels/HotelDetail';
import BookRoom from './Pages/BookRoom/BookRoom';
import FlightCheckout from './Pages/Flight/FlightCheckout';
import BookPackage from './Pages/UmrahPackages/BookPackage';
// import Confirmation from './Pages/BookingConfirmation/Confirmation';
import PackageCheckout from './Pages/UmrahPackages/Package_checkout';
import PackageInvoice2 from './Pages/UmrahPackages/PackageInvoice2';
import FlightListing from './Pages/Flight/FlightListing';
import FlightInvoice from './Pages/Flight/FlightInvoice';
import UmrahPackage2 from './Pages/UmrahPackages/umrahpackage2';
import FaqPage from './Pages/FooterPages/faqPage';
import PrivacyPolicy from './Pages/FooterPages/PrivacyPolicyPage';
import TermsConditions from './Pages/FooterPages/TermsConditions';
import ComplaintPolicy from './Pages/FooterPages/ComplaintPolicy';
import Activities from './Pages/Activities/Activities';
import HajjDetail from './Pages/Hajj/HajjDetail';
import ActivityDetail from './Pages/Activities/ActivityDetail';
import ActivityCheckout from './Pages/Activities/ActivityCheckout';
// import ActivityInvoice from './Pages/Activities/ActivityInvoice';
import ConfirmationMessage from './Pages/Hajj/ConfirmationMessage';
import TransferListing from './Pages/Transfer/TransferListing';
import TransferDetail from './Pages/Transfer/TransferDetail';
import TransferCheckout from './Pages/Transfer/TransferCheckout';
// import TransferInvoice from './Pages/Transfer/TransferInvoice';
import AboutSaudi from './Pages/KnowBeforeGo/AboutSaudi'
import TravelTips from './Pages/KnowBeforeGo/TravelTips'
import UsefulContacts from './Pages/KnowBeforeGo/UsefulContacts'
import HomeNew from './Pages/Home/HomeNew';
import Login from './Pages/AuthPages/Login';
import Dashboard from './Pages/Dashboard/index'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import HotelInvoice from './Pages/BookingConfirmation/HotelInvoice';
import TransferInvoicen from './Pages/Transfer/TransferInvoicen';
import ActivityInvoicen from './Pages/Activities/ActivityInvoicen';
function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  
  if(!token) {
    return <Login setToken={setToken} />
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(false);
  };
  return (
   <>
   <FloatingWhatsApp
      phoneNumber="+923215090400"
      accountName="Waqar International"
      statusMessage="Online"
      chatMessage="Hello! How can we help you today?"
      avatar={logo}
      style={{ left: '15px', right: 'auto' }}
      notification
    />
   <Router>
    <Routes>
    <Route path="/login" element={<Login />} />
      <Route path="/" element={<HomeNew onLogout={handleLogout}/>} />
      <Route path="/contact-us" element={<Contact onLogout={handleLogout}/>} />
      <Route path="/about-us" element={<About />} onLogout={handleLogout}/>
      {/* <Route path="/umrah-packages" element={<Umrahpackages />} /> */}
      <Route path="/umrah-package/:id" element={<ViewDeail onLogout={handleLogout}/>} />
      <Route path="/search-tour" element={<SearchTour onLogout={handleLogout}/>} />
      <Route path="/hotels" element={<Hotels onLogout={handleLogout}/>} />
      <Route path="/hotel_detail/:id" element={<HotelDetail onLogout={handleLogout}/>} />
      <Route path="/hotel_checkout" element={<BookRoom onLogout={handleLogout}/>} />
      <Route path="/book_package" element={<BookPackage onLogout={handleLogout}/>} />
      <Route path="/package_invoice/:id" element={<InvoicePage />} />
      <Route path="/package_checkout" element={<PackageCheckout onLogout={handleLogout}/>} />
      <Route path="/umrah_packages" element={<UmrahPackage2 onLogout={handleLogout}/>} />
      <Route path="/invoice_package/:id/:id/:id" element={<PackageInvoice2 />} />
      <Route path="/Flight_search" element={<FlightListing onLogout={handleLogout}/>} />
      <Route path="/Flight_checkout" element={<FlightCheckout onLogout={handleLogout}/>} />
      <Route path="/Flight_invoice/:id" element={<FlightInvoice />} />
      <Route path="/faqs" element={<FaqPage onLogout={handleLogout} />} />
      <Route path="/complaint_policy" element={<ComplaintPolicy onLogout={handleLogout}/>} />
      <Route path="/privacy_policy" element={<PrivacyPolicy onLogout={handleLogout}/>} />
      <Route path="/terms_and_conditions" element={<TermsConditions onLogout={handleLogout}/>} />
      <Route path="/hotel_booking_invoice/:id" element={<HotelInvoice />} />
      <Route path="/activities" element={<Activities onLogout={handleLogout}/>} />
      <Route path="/activity_details/:id" element={<ActivityDetail onLogout={handleLogout}/>} />
      <Route path="/activity_checkout" element={<ActivityCheckout onLogout={handleLogout}/>} />
      <Route path="/activity_invoice/:id" element={<ActivityInvoicen />} />
      <Route path="/hajj" element={<HajjDetail onLogout={handleLogout}/>} />
      <Route path="/hajj-confirmation" element={<ConfirmationMessage onLogout={handleLogout}/>} />
      <Route path="/transfer-search" element={<TransferListing onLogout={handleLogout}/>} />
      <Route path="/transfer-detail" element={<TransferDetail onLogout={handleLogout}/>} />
      <Route path="/transfer-checkout" element={<TransferCheckout onLogout={handleLogout}/>} />
      <Route path="/transfer_invoice/:id" element={<TransferInvoicen />} />
      <Route path="/about_saudi" element={<AboutSaudi onLogout={handleLogout}/>} />
      <Route path="/travel_tips" element={<TravelTips onLogout={handleLogout}/>} />
      <Route path="/customer_dashboard" element={<Dashboard onLogout={handleLogout}/>} />
      <Route path="/useful_contacts" element={<UsefulContacts onLogout={handleLogout}/>} />
    </Routes>
   
    </Router>
   
   </>
  );
}

export default App;
