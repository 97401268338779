import React,{useState} from "react";
import Layout from "../../Components/Layout/Layout";
import bgimage from '../../Images/Activity/cover.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faAngleDown
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ActivityCard from "../../Components/Activity/ActivityCard";
import { useSelector } from "react-redux";
import ActivitySearch from "../../Components/SearchBar/ActivitySearch";
function Activities ({ onLogout }){
  const [ShowRatingFilter, setShowRatingFilter] = useState(false);
  const [starRating, setStarRating] = useState({rating5:'0',rating4:'0',rating3:'0',rating2:'0',rating1:'0',type:'Stars'});
  const ActivityListing=useSelector((state) => state.hotels.ActivityListing);
const[newActivityListing,setNewActivityListing]=useState(ActivityListing.tours);
const[FilterActivityListing,setFilterActivityListing]=useState(ActivityListing.tours);
const handleCheckboxChange = (event) => {
  const { name, value } = event.target;

        setStarRating((prevDetails) => {
          const updatedStarRating = { ...prevDetails };
          if (updatedStarRating[name] !=="0") {
            updatedStarRating[name]="0";
          } else {
            updatedStarRating[name] =value;
          }

          return updatedStarRating;
        });
      };
      
      const filterbyStars=()=>{
        setShowRatingFilter(false);
        if(starRating.rating1==='0' && starRating.rating2==='0' && starRating.rating3==='0' && starRating.rating4==='0' && starRating.rating5==='0'){
          setFilterActivityListing(newActivityListing);
          
        }else{
          console.log(newActivityListing);
        const filteredTours = newActivityListing.filter((tour) => {
          const hotelRating = tour.starts_rating;
          if(hotelRating !=='')
          {
            return Object.keys(starRating).some((ratingKey) => Number(hotelRating) === Number(starRating[ratingKey]));

          }
        });
        setFilterActivityListing(filteredTours);
        
      }

      };

      const ToggleRatingFilter=()=>{
        setShowRatingFilter(!ShowRatingFilter);
      };
    return(
        <>
         <Modal isOpen={ShowRatingFilter} toggle={ToggleRatingFilter}>
          <ModalHeader toggle={ToggleRatingFilter}>Star Rating Filter</ModalHeader>
          <ModalBody>
          <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' 
                      className='custom-textbox' 
                      onChange={handleCheckboxChange} name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox'  onChange={handleCheckboxChange} name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                </ul>
                <button onClick={filterbyStars} className='btn btn-warning m-2'>Apply</button>
                </div>
                  
          </ModalBody>
        </Modal>
        <Layout logout={onLogout}>
      <div className='container-fluid px-4'>
        <div className="row mt-3"><ActivitySearch/> </div>
      </div>
      <div className='container-fluid px-4 hotel-top mt-3'>
        <div className='row mt-2'>
          <div className='col-md-3 col-md-pull-9 '>
            <div class="mobile-Filter-info">
                <ul>
                    <li><p onClick={ToggleRatingFilter}><FontAwesomeIcon icon={faStar}/><span > Rating</span></p>
                    </li>
                </ul>
            </div>
            <div className='page-sidebar hide-page_filter'>
              <div className='widget widget_has_radio_checkbox pb-3' style={{borderBottom:'0'}}>
                <div className='filter-show-hide'>                
                  <h4 className='uitk-heading uitk-heading-7 mb-2'>Star Rating</h4>                
                </div>
                <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' 
                      className='custom-textbox' 
                      onChange={handleCheckboxChange} name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox'  onChange={handleCheckboxChange} name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                </ul>
                <button onClick={filterbyStars} className='btn  search-btn1'>Apply</button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-9 col-md-push-3'>
            <section data-stid="results-header" class="uitk-spacing uitk-spacing-margin-blockstart-one mb-3">
              <header style={{boxShadow:'none !important', backgroundColor:'transparent'}}>
                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid tour-main">
                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start tour-5">
                    <div data-stid="results-header-messages">
                      <a href="https://www.expedia.com/lp/b/sort-order-info" target="_blank" rel="noopener" analytics="[object Object]" class="uitk-more-info-trigger uitk-more-info-trigger-size-small uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading">
                        <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">
                          <h5 className='title font-size-22 tc mb-2' id='tours_result'>
                              {ActivityListing.tours.length} Result found
                          </h5>
                         
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </header>
            </section>
            <ActivityCard newActivityListing={FilterActivityListing}/>
          </div>
        </div>
      </div>
        </Layout>
        </>
    );
}

export default Activities;