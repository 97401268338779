import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import logo from '../../Images/home4/SiteLogo.png'
import PropTypes from 'prop-types'
import Axios from 'axios'
import image from './644752.jpg'
import { useDispatch } from 'react-redux'
import { UserLoginResponse } from '../../Redux/Actions/actions'
import Loading from '../../Components/Loading/Loader'
import { ApiEndPoint,AuthToken } from '../../Components/GlobalData/GlobalData'
const Login = ({ setToken }) => {
  const authtoken=AuthToken();
  const [country, setCountry] = useState([])
  const [city, setCity] = useState([])
  const dispatch=useDispatch();
  const [userData, setUserData] = useState({ username: '', password: '' })
  const [signupData, setSignupData] = useState({
    title: 'MR',
    fname: '',
    lname: '',
    email: '',
    cname: '',
    caddress: '',
    country: '',
    phno: '',
    zcode: '',
    pass: ''
  })
  const [signUp, setsignUp] = useState(false)
  const [signUpCity, setsignUpCity] = useState('')
  const [loader, setLoader] = useState(false)
  const endpoint = ApiEndPoint()
  // const errorMessage = useSelector(state => state.auth.errorMessage);
  useEffect(() => {
    GetCountries()
  }, [])

  function handleChange (e) {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }
  function handleSignupChange (e) {
    if (e.target.name === 'country') {
      const code = e.target.value
      const selectedcountry = country.find(item => item.iso2 === code)
      GetCountryCities(selectedcountry.id)
    }
    setSignupData({ ...signupData, [e.target.name]: e.target.value })
  }
  function handleSignupChangeCity (e) {
    setsignUpCity(e.target.value)
  }
  const HandleLogin = async () => {
    const data = {
      token:authtoken,
      email: userData.username,
      password: userData.password
    }
    setLoader(true)
    try {
      const response = await Axios.post(
        endpoint + '/api/login_customer_submit',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setLoader(false)
      if (response.data.status === 'error') {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        setToken('true')
        dispatch(UserLoginResponse(response.data.b2b_agent));
        localStorage.setItem('token', response.data.b2b_agent.token)
      }
    } catch (error) {
      setLoader(false)
      console.error('Error:', error)
    }
  }
  const GetCountries = async () => {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/allcountriesfetch',
        headers
      )

      // Handle the response data
      setSignupData({
        ...signupData,
        country: response.data.countries[0]?.iso2
      })
      setCountry(response.data.countries)
      GetCountryCities(response.data.countries[0]?.id)
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const GetCountryCities = async id => {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    const data = {
      id: id
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/countryCites_apis',
        data,
        headers
      )

      setCity(response.data.country)
      setsignUpCity(response.data.country[0]?.name)
      // setSignupData({ ...signupData, city: response.data.country[0]?.name })
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }
  const HandleRegister = async () => {
    if (signupData.fname === '') {
      toast.error('Please Enter First Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.lname === '') {
      toast.error('Please Enter Last Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.email === '') {
      toast.error('Please Enter Your Email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (!isValidEmail(signupData.email)) {
      toast.error('Please Enter a Valid Email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.cname === '') {
      toast.error('Please Enter Company Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.caddress === '') {
      toast.error('Please Enter Company Address.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.country === '') {
      toast.error('Please Select Country.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signUpCity === '') {
      toast.error('Please Select City.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.phno === '') {
      toast.error('Please Enter Phone Number.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.zcode === '') {
      toast.error('Please Enter Zip Code.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.pass === '') {
      toast.error('Please Enter Password.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    const data = {
      token:authtoken,
      email: signupData.email,
      password: signupData.pass,
      title: signupData.title,
      first_name: signupData.fname,
      last_name: signupData.lname,
      company_name: signupData.cname,
      company_address: signupData.caddress,
      country: signupData.country,
      city: signUpCity,
      phone_no: signupData.phno,
      zip_code: signupData.zcode,
      otp_code: ''
    }
    setLoader(true)
    try {
      const response = await Axios.post(
        endpoint + '/api/register/customer/submit',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setLoader(false)
      if (response.data.status === 'success') {
        setsignUp(!signUp)
        toast.success('Thank you for registration with Waqar International.Our team will review your details and confirm your account with in 24 to 48 hours.', {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const Change = () => {
    setsignUp(!signUp)
  }
  return (
    <>
      <ToastContainer />
      {loader && <Loading />}
      {!signUp ? (
        <section
          class='vh-100 '
          style={{ backgroundColor: 'rgb(233 220 204)' }}
        >
          <div class='container py-5 h-100'>
            <div class='row d-flex justify-content-center align-items-center h-100'>
              <div class='col col-xl-10'>
                <div class='card' style={{ borderRadius: '1rem' }}>
                  <div class='row g-0'>
                    <div class='col-md-6 col-lg-5 d-none d-md-block'>
                      <img
                        src={image}
                        alt='login form'
                        class='img-fluid'
                        style={{ borderRadius: '1rem 0 0 1rem' }}
                      />
                    </div>
                    <div class='col-md-6 col-lg-7 d-flex align-items-center'>
                      <div class='card-body p-2 p-lg-5 text-black'>
                       
                          <div class='d-flex align-items-center justify-content-center mb-3 pb-1'>
                            <i
                              class='fas fa-cubes fa-2x me-3'
                              style={{ color: '#ff6219' }}
                            ></i>
                            <span class='h1 fw-bold mb-0'><img style={{width:'4em'}} src={logo}/></span>
                          </div>

                          <h5
                            class='fw-normal mb-3 pb-3'
                            style={{ letterSpacing: '1px' }}
                          >
                            Sign into your account
                          </h5>

                          <div data-mdb-input-init class='form-outline mb-4'>
                            <label class='form-label' for='form2Example17'>
                              Email address
                            </label>
                            <input
                              type='email'
                              id='form2Example17'
                              name='username'
                              placeholder='Enter Username'
                              value={userData.username}
                              onChange={handleChange}
                              class='form-control '
                            />
                          </div>

                          <div data-mdb-input-init class='form-outline mb-4'>
                            <label class='form-label' for='form2Example27'>
                              Password
                            </label>
                            <input
                              type='password'
                              id='form2Example27'
                              onChange={handleChange}
                              placeholder='Enter Password'
                              value={userData.password}
                              name='password'
                              class='form-control '
                            />
                          </div>

                          <div class='pt-1 mb-4'>
                            <button
                            onClick={HandleLogin}
                              data-mdb-button-init
                              data-mdb-ripple-init
                              class='btn btn-success btn-block'
                              style={{ width: '100%' }}
                              type='button'
                            >
                              Login
                            </button>
                          </div>

                          {/* <a class='small text-muted' href='#!'>
                            Forgot password?
                          </a> */}
                          <p class='mb-5 pb-lg-2' style={{ color: '#393f81' }}>
                            Don't have an account?{' '}
                            <a  onClick={Change} style={{ color: 'blue',cursor:'pointer' }}>
                              Register here
                            </a>
                          </p>
                          {/* <a href='#!' class='small text-muted'>
                            Terms of use.
                          </a>
                          <a href='#!' class='small text-muted'>
                            Privacy policy
                          </a> */}
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) :(
        <section
          class='vh-100 '
          style={{ backgroundColor: 'rgb(233 220 204)' }}
        >
          <div class='container py-5 h-100'>
            <div class='row d-flex justify-content-center align-items-center h-100'>
              <div class='col col-xl-10'>
                <div class='card' style={{ borderRadius: '1rem' }}>
                  <div class='row g-0'>
                    <div class='col-md-6 col-lg-5 d-none d-md-block'>
                      <img
                        src={image}
                        alt='login form'
                        class='img-fluid'
                        style={{ borderRadius: '1rem 0 0 1rem' }}
                      />
                    </div>
                    <div class='col-md-6 col-lg-7 d-flex align-items-center'>
                    <div class='row p-3 p-sm-4 p-md-5'>
                        <a href='index.html'>
                          {/* <img
                            class='img-fluid mb-4'
                            src={logo}
                            width='200'
                            alt='logo'
                          /> */}
                        </a>
                        <h1 class='mb-2 text-center fs-2'>Create New Account</h1>
                        
                        <div class='col-lg-6  form-floating mb-4'>
                        <select  onChange={handleSignupChange} value={signupData.title} name='title' class="form-control" aria-label="Default select example">
                            <option value="MR">MR.</option>
                            <option value="MRS">MRS.</option>
                            <option value="MISS">MISS.</option>
                          </select>
                            <label style={{left:'auto'}}>
                              Select Title*
                            </label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                            <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.fname}
                              name='fname'
                              class='form-control'
                              required=''
                            />
                            <label style={{left:'auto'}}>First Name*</label>
                          </div>
                        <div class='col-lg-6  form-floating mb-4'>
                            <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.lname}
                              name='lname'
                              class='form-control'
                              required=''
                            />
                            <label style={{left:'auto'}}>Last Name*</label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                            <input
                              type='email'
                              onChange={handleSignupChange}
                              value={signupData.email}
                              name='email'
                              class='form-control'
                              required=''
                            />
                            <label style={{left:'auto'}}>User Email*</label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                            <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.cname}
                              name='cname'
                              class='form-control'
                              required=''
                            />
                            <label style={{left:'auto'}}>Company Name*</label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                            <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.caddress}
                              name='caddress'
                              class='form-control'
                              required=''
                            />
                            <label style={{left:'auto'}}>Company Address*</label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                          <select  onChange={handleSignupChange} value={signupData.country} name='country' class="form-control" aria-label="Default select example">
                          {country.map(item => (
                                    <option key={item.id}  value={item.iso2}>
                                      {item.name}
                                    </option>
                                  ))}
                          </select>
                            <label style={{left:'auto'}}>Select Country*</label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                          <select value={signUpCity} onChange={handleSignupChangeCity} name='city' class="form-control" aria-label="Default select example">
                          {city.map(item => (
                                    <option key={item.id}  value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                          </select>
                            <label style={{left:'auto'}}>Select City*</label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                            <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.phno}
                              name='phno'
                              class='form-control'
                              onInput={(e) => (e.currentTarget.value = e.currentTarget.value .replace(/[^0-9.]/g, "") .replace(/(\..*?)\..*/g, "$1"))}
                              required=''
                            />
                            <label style={{left:'auto'}}>Phone Number*</label>
                          </div>
                        <div class='col-lg-6  form-floating mb-4'>
                             <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.zcode}
                              name='zcode'
                              class='form-control'
                              required=''
                            />
                            <label style={{left:'auto'}}>Zip Code*</label>
                          </div>
                          <div class='form-floating mb-4'>
                            <input
                              type='password'
                              onChange={handleSignupChange}
                              value={signupData.pass}
                              name='pass'
                              class='form-control'
                              id='password-field'
                              required=''
                            />
                            <label style={{left:'auto'}}>Password</label>
                            <span class='toggle-password position-absolute top-50 end-0 translate-middle-y me-3 fa-regular fa-eye'></span>
                          </div>

                          <div class='form-group'>
                            <button
                              onClick={HandleRegister}
                              type='submit'
                              style={{width:'100%'}}
                              class='btn btn-primary full-width font--bold '
                            >
                              Create Account
                            </button>
                          </div>

                          <p class='mt-2 pb-lg-2' style={{ color: '#393f81' }}>
                            Already have an account?{' '}
                            <a  onClick={Change} style={{ color: 'blue',cursor:'pointer' }}>
                              Login here
                            </a>
                          </p>
                     
                        <div class='text-primary-hover mt-3 text-center'>
                          {' '}
                          Copyrights ©2024 Waqar International{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default Login
