import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Loading from '../Loading/Loader'
import { fetchHotelDetail } from '../../Redux/Actions/actions'
import { ApiEndPoint, Hotelapitoken } from '../GlobalData/GlobalData'
function HotelMap (props) {
  const [map, setMap] = useState(null);
  const [showPrice, setShowPrice] = useState(true);
  const [baseCName, setBaseCName] = useState('SAR');
  const [Loadingpage, setLoadingpage] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState([]);
  var endpoint = ApiEndPoint();
  var apitoken = Hotelapitoken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  useEffect(() => {
    if (props.newhotelbasecurrency === null) {
      setShowPrice(false)
    } else {
      setBaseCurrency(props.newhotelbasecurrency)
    }
    if (typeof window.google === 'undefined' ) {
      return; // Wait for the API to load
    }
    // Initialize Google Maps once component mounts
         var googleMap = new window.google.maps.Map(
          document.getElementById('map'),
          {
            center: { lat: props.hotelsearch.lat, lng: props.hotelsearch.long },
            zoom: 11
          }
        );
    
      setMap(googleMap)
    // Clean up function to remove event listeners when component unmounts
    return () => {
      window.google.maps.event.clearInstanceListeners(googleMap)
    }
  }, [props.newhotelbasecurrency, CurrencyRates, GBPCurrencyRates])
  useEffect(() => {
    if (map) {
      props.hotels.hotels_list.forEach((hotel, index) => {
        const marker = new window.google.maps.Marker({
          position: {
            lat: Number(hotel.latitude_Hotel),
            lng: Number(hotel.longitude_Hotel)
          },
          map: map,
          // icon: svgMarker,
          title: hotel.hotel_name
        })

        marker.addListener('click', async function () {
          const imageUrl = await Facilityfilter(
            hotel.hotel_id,
            hotel.hotel_provider
          )
          const MarkupPrice = calculateMarkup(
            hotel.min_price,
            hotel.admin_markup,
            hotel.admin_markup_type,
            hotel.customer_markup,
            hotel.customer_markup_type
          )
          const infoWindowContent = `
                      <div>
                         ${
                           imageUrl
                             ? `<img class='map-img' src="${imageUrl}" alt="${hotel.hotel_name} image" />`
                             : ''
                         }
                          <h5 class='map-hotel-heading' >${hotel.hotel_name}</h5>
                          <p>Rating: ${hotel.stars_rating} stars</p>
                           ${
                             showPrice
                               ? `<p style="color:green;">
                              <super>
                                  ${
                                    CurrencyRates === undefined
                                      ? baseCName
                                      : CurrencyRates.selectedcurrency
                                  } 
                                  ${renderPrice(MarkupPrice)}
                              </super><br/>
                              <span style="color:#000;font-size:12px;">Inclusive of vat and taxes</span>
                          </p>`
                               : `<p style="color:green;">
                              <super>
                                  ${hotel.hotel_curreny} ${MarkupPrice.toFixed(
                                   2
                                 )}
                              </super><br/>
                              <span style="color:#000;font-size:12px;">Inclusive of vat and taxes</span>
                          </p>`
                           }
                       <button style="color:#fff;font-size:14px; background-color:#63ab45;border-radius:5px;padding:.5em;margin-top:.8em" id="hotel-button-${
                         hotel.hotel_id
                       }" data-index="${index}">More Info</button>
                      </div>
                  `
          const infoWindow = new window.google.maps.InfoWindow({
            content: infoWindowContent
          })
          infoWindow.open(map, marker)
          setTimeout(() => {
            const button = document.getElementById(
              `hotel-button-${hotel.hotel_id}`
            )
            if (button) {
              button.addEventListener('click', () => {
                const hotelIndex = button.dataset.index
                FilterNewHotel(hotel.hotel_id, hotelIndex)
              })
            }
          }, 0)
        })
      })
    }
  }, [map, props.hotels, showPrice, CurrencyRates, baseCurrency])
  const Facilityfilter = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/hotels/mata_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      return response.data.details_data.image
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
      return null
    }
  }
  const calculateMarkup = (
    price,
    adminmarkup,
    admintype,
    clientmarkup,
    clienttype
  ) => {
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = Number(price)
    if (Number(adminmarkup) !== 0) {
      if (admintype === 'Percentage') {
        markupprice = (price * Number(adminmarkup)) / 100
      } else {
        markupprice = Number(adminmarkup)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
      // savemarkup.admin=adminmarkupprice;
      // savemarkup.final=finalpricemarkup;
    }
    if (Number(clientmarkup) !== 0) {
      if (clienttype === 'Percentage') {
        markupprice = (finalpricemarkup * Number(clientmarkup)) / 100
      } else {
        markupprice = Number(clientmarkup)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
    }
    return finalpricemarkup
  }
  const FilterNewHotel = async (id, index) => {
    const hotelRoomdetail = props.hotels.hotels_list.filter(
      item => item.hotel_id == id
    )
    setLoadingpage(true)
    try {
      const data = {
        token: apitoken,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id)
      }
      const response = await Axios.post(
        endpoint + '/api/hotels/details_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )

      // Handle the API response here
      dispatch(fetchHotelDetail(response.data.hotel_details))
      navigate(`/hotel_detail/${id}`, { state: { index } })
      setLoadingpage(false)
    } catch (error) {
      // Handle errors here
      setLoadingpage(false)
      console.error('Error:', error)
    }
  }
  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      const gbpprice = baseCurrency[baseCName] // Use square brackets to access the property
      var baseprice = Number(gbpprice) * Number(price)
    } else {
      var select123 = CurrencyRates.selectedcurrency
      const gbpprice = baseCurrency[baseCName]
      var baseprice1 = Number(gbpprice) * Number(price)
      const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
      var baseprice = Number(gbpprice2) * Number(baseprice1)
    }
    return baseprice.toFixed(0)
  }
  return (
    <>
      {Loadingpage && <Loading />}
      <div id='map' style={{ height: '400px', width: '100%' }}></div>
    </>
  )
}

export default HotelMap
