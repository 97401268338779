import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faLocation } from '@fortawesome/free-solid-svg-icons'
import noImage from '../../Images/Hotels/no-img.jpg'
import moment from 'moment'
import Loader from '../../Components/Loading/Loader'
import bgimage from '../../Images/Hotels/bg.jpg'
import image from '../../Images/View Detail/1.jpeg'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ApiEndPoint,Hotelapitoken } from '../../Components/GlobalData/GlobalData'
import { fetchCheckoutDetail } from '../../Redux/Actions/actions'
import Images from '../../Components/Images/images'
import Layout from '../../Components/Layout/Layout'
import RoomFacilities from '../../Components/Hotel/RoomFacility'
import Axios from 'axios'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
function TransferDetail ({ onLogout }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const todayDate = new Date();
  var endpoint=ApiEndPoint();
  const { index } = location.state || {};
  const [selectedDate, setSelectedDate] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPrice, setShowPrice] = useState(true);
  const [baseCName, setBaseCName] = useState('SAR');
  const [counter, setCounter] = useState(0);

  const [hotelSelectedRooms, setHotelSelectedRooms] = useState([]);

  const navigate = useNavigate();
  const hoteldetail = useSelector(state => state.hotels.hoteldetail);
  const seleectedhotelfromlist = useSelector(
    state => state.hotels.hotels.hotels_list[index]
  );

  let { id } = useParams();
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const ReduxSearchData = useSelector(state => state.hotels.hsearch);
  
  let Checkin = moment(ReduxSearchData.check_in);
  let checkout = moment(ReduxSearchData.check_out);
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'));
  const latitude = hoteldetail.latitude;
  const longitude = hoteldetail.longitude;
  var mapUrl =
    'https://maps.google.com/maps?width=50%25&height=600&hl=en&q=' +
    latitude +
    ',' +
    longitude +
    '&t=&z=19&ie=UTF8&iwloc=B&output=embed';
 
  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName)
  };
  const handleButtonClick2 = (buttonName) => {
    setActiveButton(buttonName)
  };

  useEffect(() => {
    let NewArray = [];
    let occurrences = {};
    // Iterate over the adults and childs arrays
  for (let i = 0; i < ReduxSearchData.Adults.length; i++) {
    let adult = ReduxSearchData.Adults[i];
    let child = ReduxSearchData.children[i];
 // Create a unique identifier for the pair
 let pairKey = `${adult}-${child}`;

 // Update the occurrences object
 occurrences[pairKey] = (occurrences[pairKey] || 0) + 1;

 // Only add to the result if the current qty is the maximum
 if (occurrences[pairKey] === 1) {
  NewArray.push({ adult, child, qty: occurrences[pairKey],status:false });
 } else {
     // Update the qty for the existing entry
     NewArray.find(entry => entry.adult === adult && entry.child === child).qty = occurrences[pairKey];
 }
}

    setResult(NewArray);
  }, [ReduxSearchData]);
 
  const selectedRoomData=(index, event)=>{
    event.preventDefault();
    event.stopPropagation();
    const selectedroom = hoteldetail.rooms_options[index];
      for (let i = 0; i < result.length; i++) {
        if(result[i].adult===selectedroom.adults &&  result[i].child===selectedroom.childs  &&  result[i].qty===selectedroom.rooms_qty)
        {
          if( result[i].status===false){
            const key = { rateKey: selectedroom.booking_req_id, index: index }
            const newSelectedRoom = {
              room_rate_key: JSON.stringify(key),
              rooms_qty: selectedroom.rooms_qty,
              index: index,
            }
            result[i].status=true;
            setSelectedRooms([...selectedRooms, newSelectedRoom])
            setHotelSelectedRooms([...hotelSelectedRooms, key])
          }else{
            if (selectedRooms.some(room => room.index === index)) {
              const updatedSelectedRooms = selectedRooms.filter(  room => room.index !== index )
                    const newroom = hotelSelectedRooms.filter(item => item.index !== index);
                    setSelectedRooms(updatedSelectedRooms);
                    setHotelSelectedRooms(newroom);
                    result[i].status=false;
            }else{
              toast.error('Select the room according to the search criteria.', {
                        position: toast.POSITION.TOP_RIGHT
                    });
            }
          }
         
        }
          
      }

  };
  const BookRoom = async () => {
    
    if(hotelSelectedRooms.length !==0){ 
      debugger
      if(hotelSelectedRooms.length !== result.length)
      {
            toast.error('Please Select the room according to the search criteria.', {
              position: toast.POSITION.TOP_RIGHT
          });
        return;
      }

    const roomdata = {
      rooms_select_data: JSON.stringify(selectedRooms),
      hotel_index: index,
      hotelbeds_select_room: hotelSelectedRooms
    }
    const finaldata = {
      token:
        'r9fdvwRyF35JUnD6xXdRiDELANYjtfASzPmyGol4-1PN461EY50LbXcqkfEfISsOJDrnFDJbuMzPuxTz37zFWGWBVemQGhi2SYLrr-MZ75vJSAiV73z94UOVrDz5P6R-0KjFqr9XR6P2857snQbcKTUn9YNqjBOQQIkXENeO7tmjxdTJs2KUVoXqo6fFyT9TTq99eKe288N-wyanZXxOsfABWPjtSom2oKLVz6vJnn1WeQwHSp7VnzPUqq53rn80eFXNBSMIiEXBdDmlsokRYSa0evDrQKluhnIzMYkRiazxtnkb-z5Xj0tQReTTHsLz1sgnit2mRGGzP4EIdBK8TiLuEN7GD1kmOT3CMreL7ELrI4yxmEbnYyflICtG-ySk3aZkk8iM9mRZlA7CS10Zuj-C0HEBOFW8vMzy4Eq2CET5WN62S1xe0HPAOrDVwO6jDvVpKEMwm-NiyyjkU8oTTlgYpN77pXtfFjKPTF0julnAMC6cPzxZOGBIkRv0',
      request_data: JSON.stringify(roomdata),
      selected_hotel: JSON.stringify(seleectedhotelfromlist),
      selected_hotel_details: JSON.stringify({
        check_in: ReduxSearchData.check_in,
        check_out: ReduxSearchData.check_out,
        hotel_address: hoteldetail.hotel_address,
        hotel_country: hoteldetail.hotel_country,
        hotel_city: hoteldetail.hotel_city,
        atitude: hoteldetail.latitude,
        longitude: hoteldetail.longitude
      }),
      req_index: index
    }
    setLoading(true)
    try {
      const response = await Axios.post(
        endpoint+'/api/hotels/checkavailability',
        finaldata,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            // 'Access-Control-Allow-Headers':
            //   'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      // Handle the API response here
      if(response.data.status==='error'){
        setLoading(false);
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
      });
      return;
      };
      if(response.data !==''){
        dispatch(fetchCheckoutDetail(response.data.hotels_data))
        navigate('/hotel_checkout')
      }
      setLoading(false)
    } catch (error) {
      // Handle errors here
      setLoading(false);
      console.error('Error:', error)
    }
  }else{
    toast.error('Please Select Room First.', {
      position: toast.POSITION.TOP_RIGHT
  });
  }
  };

  const renderPrice = (price) =>{
    const userData = localStorage.getItem('HotelCurrency');
    const Rates = JSON.parse(userData);
      if(userData !==null){
          if(CurrencyRates===undefined){
            const gbpprice = Rates.conversion_rates[baseCName]; // Use square brackets to access the property
            var baseprice = (Number(gbpprice) * Number(price))
        
          }else{
            var select123 = CurrencyRates.selectedcurrency;
            const gbpprice = Rates.conversion_rates[baseCName];
            var baseprice1 = (Number(gbpprice) * Number(price));
            const gbpprice2 = GBPCurrencyRates.conversion_rates[select123]; // Use square brackets to access the property
            var baseprice = (Number(gbpprice2) * Number(baseprice1));
          }
          return baseprice.toFixed(0)
       }else{
        setShowPrice(false);
       }
  };
  const calculateMarkup = (price) => {
    if(Object.keys(hoteldetail).length !==0){
    let markupprice=0;
    let adminmarkupprice=0;
    let clientmarkupprice=0;
    let finalpricemarkup=Number(price);
    if(Number(hoteldetail.admin_markup) !== 0)
   {
    if(hoteldetail.admin_markup_type === "Percentage")
    {
      markupprice=( price * Number(hoteldetail.admin_markup))/100;
    }else{
      markupprice= Number(hoteldetail.admin_markup);
     }
      adminmarkupprice=markupprice;
      finalpricemarkup +=markupprice
      
   }
   if(Number(hoteldetail.customer_markup) !== 0)
   {
    if(hoteldetail.customer_markup_type=== "Percentage")
    {
      markupprice= (finalpricemarkup * Number(hoteldetail.customer_markup))/100;
    }else{
      markupprice= Number(hoteldetail.customer_markup);
     }
     clientmarkupprice=markupprice;
      finalpricemarkup +=markupprice;
   }
   return finalpricemarkup.toFixed(2);
  }
  };
 
  return (
    <>
   
      {loading ? (
        <Loader />
      ) : (
        <Layout logout={onLogout}>
        <div className='uitk-spacing uitk-spacing-margin-medium-inline-six'>
          <ToastContainer/>
          <div style={{marginTop:'1px', backgroundColor:'white'}}>
            <div class="uitk-spacing uitk-spacing-padding-block-two uitk-spacing-padding-inline-two">
              <div class="uitk-layout-flex uitk-layout-flex-align-items-center" style={{minHeight:'3rem'}}>
                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                  <button type="button" role="link" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium">
                    <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-one uitk-icon-directional uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
                    </svg>See all cars
                  </button>
                </div>
                <div data-stid="favorite-save-button">
                  <div>
                    <div data-stid="outlined-save-button">
                      <div class="">
                        <button type="button" aria-label="Save property to a trip" class="favorite-button favorite-button-button-with-label">
                          <svg class="uitk-icon favorite-button-fill favorite-button-fill-pill" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="m12 22-8.44-8.69a5.55 5.55 0 0 1 0-7.72C4.53 4.59 5.9 4 7.28 4c1.4 0 2.75.59 3.72 1.59l1 1.02 1-1.02c.97-1 2.32-1.59 3.72-1.59 1.4 0 2.75.59 3.72 1.59a5.55 5.55 0 0 1 0 7.72L12 22Z" clip-rule="evenodd"></path>
                          </svg>
                          <svg class="uitk-icon favorite-button-border favorite-button-border-pill favorite-button-border-color-highlighed" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="m12 22 8.44-8.69a5.55 5.55 0 0 0 0-7.72A5.24 5.24 0 0 0 16.72 4 5.22 5.22 0 0 0 13 5.59L12 6.6l-1-1.02a5.2 5.2 0 0 0-7.44 0 5.55 5.55 0 0 0 0 7.72L12 22Zm0-2.87-7-7.21a3.55 3.55 0 0 1 0-4.94C5.6 6.36 6.44 6 7.28 6c.84 0 1.69.36 2.3.98L12 9.48l2.43-2.5c.6-.62 1.45-.98 2.29-.98.84 0 1.68.36 2.28.98a3.55 3.55 0 0 1 0 4.94l-7 7.2Z" clip-rule="evenodd" opacity=".9"></path>
                          </svg>
                          <span class="favorite-button-text">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uitk-card-content-section uitk-card-content-section-border-block-end uitk-card uitk-card-roundcorner-all custom-css-flat-border-top custom-css-flat-border-bottom uitk-card-has-primary-theme" data-stid="uitk-card-content-section-navbar" style={{position:'sticky'}}>
              <div class="uitk-tabs-container">
                <ul class="uitk-tabs uitk-tabs-natural background-primary uitk-tabs-jumplink uitk-tabs-with-border" role="list">
                  <li role="listitem" className={activeButton === 'section-1' ? 'current' : 'uitk-tab'}>
                    <a href='#section-1' onClick={() => handleButtonClick2('section-1')} class="uitk-tab-anchor"
                        aria-current="true" role="link" data-toggle="tab" draggable="false">
                      <span class="uitk-tab-text">Overview</span>
                    </a>
                    <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                  </li>
                  <li role="listitem" className={activeButton === 'section-2' ? 'current' : 'uitk-tab'}>
                    <a href='#section-2' onClick={() => handleButtonClick('section-2')} className="uitk-tab-anchor"
                        aria-current="false" role="link" data-toggle="tab" draggable="false">
                      <span class="uitk-tab-text">Location</span>
                    </a>
                    <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                  </li>
                  <li role="listitem" className={activeButton === 'section-3' ? 'current' : 'uitk-tab'}>
                    <a href='#section-3'  onClick={() => handleButtonClick('section-3')} className="uitk-tab-anchor"
                        aria-current="false" role="link" data-toggle="tab" draggable="false">
                      <span class="uitk-tab-text">Policies</span>
                    </a>
                    <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                  </li>
                  <li role="listitem" className={activeButton === 'section-4' ? 'current' : 'uitk-tab'}>
                    <a href='#section-4'  onClick={() => handleButtonClick('section-4')} className="uitk-tab-anchor"
                        aria-current="false" role="link" data-toggle="tab" draggable="false">
                      <span class="uitk-tab-text">Extras</span>
                    </a>
                    <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='container-fluid mt-2 rounded-3'>
            <section className='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-overflow uitk-spacing uitk-flat-border-top uitk-spacing-margin-blockend-three uitk-spacing-padding-large-inline-six uitk-card-has-primary-theme'>
              <h3>{hoteldetail.hotel_name}</h3>
              <div className='row'>
                <div className='col-md-8'>
                    <div class="car-detail-section">
                        <div class="uitk-spacing uitk-spacing-padding-inline-three">
                            <div id="Overview section-1">
                                <div tabindex="-1">
                                    <div data-stid="vehicle-card" data-testid="vehicle-card" class="cis-vehicle-card">
                                        <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-overflow uitk-card-has-primary-theme">
                                            <span data-stid="car-offer-badge" data-testid="car-offer-badge">
                                                <span class="uitk-badge uitk-badge-large uitk-badge-deal-generic uitk-badge-has-text uitk-spacing vertical-align-top uitk-spacing-margin-inlineend-three uitk-spacing-margin-blockend-three">
                                                    <span class="uitk-badge-text" aria-hidden="false">Great Deal</span>
                                                </span>
                                            </span>
                                            <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap full-width">
                                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-2 uitk-layout-flex-item-flex-shrink-1 vehicle-detail-container">
                                                    <div class="vehicle-detail-desktop">
                                                        <div>
                                                            <div><h1 class="uitk-heading uitk-heading-4">Compact SUV</h1></div>
                                                            <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">MG ZS or similar</div>
                                                        </div>
                                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                                            <div class="uitk-layout-grid-item vehicle-feature-list">
                                                                <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-two">
                                                                    <div class="details-feature-list-item">
                                                                        <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd" d="M16 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM4 18c0-2.66 5.33-4 8-4s8 1.34 8 4v2H4v-2z" clip-rule="evenodd"></path>
                                                                        </svg>
                                                                        <span class="uitk-text uitk-type-300 uitk-text-default-theme">5 Passengers</span>
                                                                    </div>
                                                                </div>
                                                                <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-two">
                                                                    <div class="details-feature-list-item">
                                                                        <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M22 11h-4.17l3.24-3.24-1.41-1.42L15 11h-2V9l4.66-4.66-1.42-1.41L13 6.17V2h-2v4.17L7.76 2.93 6.34 4.34 11 9v2H9L4.34 6.34 2.93 7.76 6.17 11H2v2h4.17l-3.24 3.24 1.41 1.42L9 13h2v2l-4.66 4.66 1.42 1.41L11 17.83V22h2v-4.17l3.24 3.24 1.42-1.41L13 15v-2h2l4.66 4.66 1.41-1.42L17.83 13H22v-2z"></path>
                                                                        </svg>
                                                                        <span class="uitk-text uitk-type-300 uitk-text-default-theme">Air Conditioning</span>
                                                                    </div>
                                                                </div>
                                                                <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-two">
                                                                    <div class="details-feature-list-item">
                                                                        <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="m20.38 8.57-1.23 1.85a8.06 8.06 0 0 1-.22 7.58H5.07A8 8 0 0 1 15.58 6.85l1.85-1.23a10 10 0 0 0-6.35-1.54c-4.52.43-8.28 4-8.94 8.5A10 10 0 0 0 3.35 19c.35.6 1.02 1 1.72 1h13.85c.7 0 1.39-.39 1.74-1a10.09 10.09 0 0 0-.27-10.44l-.01.01zm-9.79 6.84c.76.76 2.06.77 2.83 0l5.66-8.49-8.49 5.66a2.03 2.03 0 0 0 0 2.83z"></path>
                                                                        </svg>
                                                                        <div class="uitk-menu uitk-menu-mounted" id="featureDialog-2">
                                                                            <button aria-label="See more about Unlimited mileage" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading uitk-menu-trigger more-info-icon" aria-expanded="false">
                                                                                <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme">Unlimited mileage</div>
                                                                                <div class="uitk-more-info-trigger-icon-container uitk-more-info-trigger-size-medium-icon-container">
                                                                                    <svg class="uitk-icon uitk-more-info-trigger-icon uitk-more-info-trigger-icon-theme-default uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                    </svg>
                                                                                </div>
                                                                            </button>
                                                                            <div class="uitk-menu-container animation-disabled uitk-menu-pos-right uitk-menu-container-autoposition" aria-hidden="true" style={{width: '265px'}}>
                                                                                <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-justify-content-space-between uitk-spacing uitk-spacing-padding-inline-three">
                                                                                    <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme uitk-layout-flex-item">Drive unlimited distance with this car at no extra cost.</div>
                                                                                    <button class="uitk-layout-flex-item feature uitk-link">
                                                                                        <svg class="uitk-icon uitk-icon-xsmall" aria-label="Close" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                            <title id="undefined-title">Close</title>
                                                                                            <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="uitk-layout-grid-item vehicle-feature-list">
                                                                <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-two">
                                                                    <div class="details-feature-list-item">
                                                                        <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd" d="M10.41 2.62 2.6 10.86c-.38.4-.59.93-.59 1.49v7.54C2 21.06 2.9 22 4 22h16c1.1 0 2-.94 2-2.1V4.1c0-1.16-.9-2.1-2-2.1h-8.17c-.53 0-1.04.22-1.42.62zM20 11.47V4.11h-8L7 9.37v2.1h13zM15 15v2h4v-2h-4z" clip-rule="evenodd"></path>
                                                                        </svg>
                                                                        <span class="uitk-text uitk-type-300 uitk-text-default-theme">5 Doors</span>
                                                                    </div>
                                                                </div>
                                                                <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-two">
                                                                    <div class="details-feature-list-item">
                                                                        <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M6 4c0 .7-.4 1.38-1 1.73V10h6V5.73c-.62-.36-1-1.03-1-1.73 0-1.08.92-2 2-2s2 .92 2 2c0 .7-.38 1.37-1 1.73V10h6V5.73c-.62-.36-1-1.03-1-1.73 0-1.08.92-2 2-2s2 .92 2 2c0 .7-.38 1.37-1 1.73V10c0 1.09-.91 2-2 2h-6v6.27c.62.36 1 1.03 1 1.73 0 1.08-.92 2-2 2s-2-.92-2-2c0-.7.38-1.37 1-1.73V12H5v6.27c.62.36 1 1.03 1 1.73 0 1.08-.92 2-2 2s-2-.92-2-2c0-.7.38-1.37 1-1.73V5.73C2.4 5.38 2 4.7 2 4c0-1.09.92-2 2-2 1.09 0 2 .92 2 2z"></path>
                                                                        </svg>
                                                                        <span class="uitk-text uitk-type-300 uitk-text-default-theme">Manual</span>
                                                                    </div>
                                                                </div>
                                                                <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-two">
                                                                    <div class="details-feature-list-item">
                                                                        <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="m19.77 7.23.01-.01-3.72-3.72L15 4.56l2.11 2.11c-.94.36-1.61 1.26-1.61 2.33a2.5 2.5 0 0 0 2.5 2.5c.36 0 .69-.08 1-.21v7.21c0 .55-.45 1-1 1s-1-.45-1-1V14c0-1.1-.9-2-2-2h-1V5c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2v16h10v-7.5h1.5v5a2.5 2.5 0 0 0 5 0V9c0-.69-.28-1.32-.73-1.77zM12 10H6V5h6v5zm6 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path>
                                                                        </svg>
                                                                        <div class="uitk-menu uitk-menu-mounted" id="featureDialog-12">
                                                                            <button aria-label="See more about Fuel info" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading uitk-menu-trigger more-info-icon" aria-expanded="false">
                                                                                <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme">Fuel: full to full</div>
                                                                                <div class="uitk-more-info-trigger-icon-container uitk-more-info-trigger-size-medium-icon-container">
                                                                                    <svg class="uitk-icon uitk-more-info-trigger-icon uitk-more-info-trigger-icon-theme-default uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                    </svg>
                                                                                </div>
                                                                            </button>
                                                                            <div class="uitk-menu-container animation-disabled uitk-menu-pos-right uitk-menu-container-autoposition" aria-hidden="true" style={{width: '265px'}}>
                                                                                <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-justify-content-space-between uitk-spacing uitk-spacing-padding-inline-three">
                                                                                    <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme uitk-layout-flex-item">Vehicle to be returned with the same amount of fuel as start of the trip to avoid refuelling charges.</div>
                                                                                    <button class="uitk-layout-flex-item feature uitk-link">
                                                                                        <svg class="uitk-icon uitk-icon-xsmall" aria-label="Close" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                            <title id="undefined-title">Close</title>
                                                                                            <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-layout-flex-item-flex-shrink-0 uitk-spacing vehicle-image-container uitk-spacing-margin-blockstart-six uitk-spacing-margin-inlineend-three">
                                                    <figure class="uitk-image uitk-image-fit-contain uitk-image-ratio-2-1 uitk-image-ratio">
                                                        <div class="uitk-image-placeholder">
                                                            <img alt="Compact SUV" class="uitk-image-media image-icons" src="https://mediaim.expedia.com/cars/39/dc662227-32cc-4c66-85ba-ee4a75e94580.jpg?impolicy=resizecrop&amp;ra=fit&amp;rh=165&amp;rw=165"/>
                                                        </div>
                                                    </figure>
                                                </div>
                                            </div>
                                            <hr class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-border-blockstart uitk-spacing-hr"/>
                                            <div>
                                                <h2 class="uitk-heading uitk-heading-5">Traveler ratings</h2>
                                                <div class="uitk-spacing uitk-spacing-padding-blockstart-four uitk-spacing-padding-blockend-one">
                                                    <div> 
                                                        <div class="uitk-layout-flex uitk-layout-flex-gap-three">
                                                            <div class="uitk-layout-grid uitk-layout-grid-align-content-space-around uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid uitk-layout-flex-item">
                                                                <div class="uitk-layout-flex uitk-layout-flex-align-content-center uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-padding-inlinestart-three uitk-spacing-padding-inlineend-three uitk-layout-grid-item">
                                                                    <div class="uitk-text uitk-type-200 uitk-type-bold uitk-text-emphasis-theme uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                                                        <div>Excellent</div>
                                                                    </div>
                                                                    <div class="uitk-text uitk-type-700 uitk-type-medium uitk-text-emphasis-theme uitk-layout-flex-item">88%</div>
                                                                    <div class="uitk-text uitk-type-100 uitk-type-regular uitk-text-default-theme uitk-layout-flex-item reviews-summary-text">positive</div>
                                                                </div>
                                                            </div>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                                                <div class="uitk-layout-flex-item vendor-logo-container">
                                                                    <div class="uitk-image-placeholder"><img alt="Budget Rental Company" class="uitk-image-media vendor-logo" src="https://mediaim.expedia.com/cars/logos/ZD.png"/></div>
                                                                </div>
                                                                <div class="uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-spacing reviews-summary-text uitk-spacing-padding-blockstart-one uitk-spacing-padding-blockend-one uitk-layout-flex-item">at 10-12 Semley Place, Victoria Station</div>
                                                                <div class="uitk-layout-flex uitk-layout-flex-justify-content-space-between">
                                                                    <div class="uitk-layout-flex-item">
                                                                        <button class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading more-info-icon">
                                                                            <div class="uitk-text uitk-type-start uitk-type-300 uitk-type-regular uitk-text-default-theme reviews-summary-text">114 verified ratings</div>
                                                                            <div class="uitk-more-info-trigger-icon-container uitk-more-info-trigger-size-medium-icon-container">
                                                                                <svg class="uitk-icon uitk-more-info-trigger-icon uitk-more-info-trigger-icon-theme-default uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-spacing uitk-spacing-margin-small-blockstart-four uitk-spacing-margin-large-blockstart-unset">
                                                                        <button class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-large">
                                                                            <div class="uitk-text uitk-type-400 uitk-type-medium uitk-text-default-theme reviews-summary-link-text">See rating details</div>
                                                                            <svg class="uitk-icon uitk-link-icon-large uitk-icon-directional" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                        <div>
                                            <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-padded uitk-card-has-global-loyalty-standard-theme" data-stid="one-key-message-card">
                                                <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three">
                                                    <div class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item">
                                                        <img class="uitk-mark uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six uitk-mark-landscape-oriented" alt="" src="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg" id="onekey__standard__always_light" />
                                                    </div>
                                                    <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap">
                                                           <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1">
                                                               <div class="uitk-spacing uitk-spacing-padding-inlineend-two">
                                                                    <h3 class="uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme">Earn OneKeyCash on your car rental when you sign in and book</h3>
                                                                </div>
                                                            </div>
                                                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                                                <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                                                    <a target="_self" data-stid="one-key-message-card-UIPrimaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-primary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-unset">Sign in</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Location section-2">
                                <div tabindex="-1">
                                    <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-spacing uitk-messaging-card uitk-flex uitk-spacing-margin-block-three uitk-card-has-primary-theme" data-stid="rental-location-detail" data-testid="rental-location-detail">
                                        <div class="trip-detail-sections">
                                            <h2 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-blockend-three">Car rental location</h2>
                                            <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-wrap">
                                                <div class="uitk-spacing uitk-spacing-padding-blockend-two uitk-layout-flex-item uitk-layout-flex-item-flex-basis-twenty_four uitk-layout-flex-item-flex-grow-1">
                                                    <div class="location-detail-segment">
                                                        <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockend-two">Pick-up</h3>
                                                        <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2" role="list">
                                                            <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                                <div class="uitk-typelist-item-icon">
                                                                    <svg class="uitk-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" d="M19 3h-1V1h-2v2H8V1H6v2H5a2 2 0 0 0-1.99 2L3 19a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm0 5v11H5V8h14zm-7 2H7v5h5v-5z" clip-rule="evenodd"></path>
                                                                    </svg>
                                                                </div>
                                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Mon, Apr 1, 10:30am</div>
                                                            </li>
                                                            <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                                <div class="uitk-typelist-item-icon">
                                                                    <svg class="uitk-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" d="M5 9a7 7 0 1 1 14 0c0 5.25-7 13-7 13S5 14.25 5 9zm4.5 0a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0z" clip-rule="evenodd"></path>
                                                                    </svg>
                                                                </div>
                                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">10-12 Semley Place, Victoria Station, London, GBR SW1W 9QL</div>
                                                            </li>
                                                            <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                                <div class="uitk-typelist-item-icon">
                                                                    <svg class="uitk-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                                                    </svg>
                                                                </div>
                                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Hours of operation
                                                                    <div>8:00am - 4:00pm</div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="uitk-spacing uitk-spacing-padding-blockend-two uitk-layout-flex-item uitk-layout-flex-item-flex-basis-twenty_four uitk-layout-flex-item-flex-grow-1">
                                                    <div class="location-detail-segment">
                                                        <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockend-two">Drop-off</h3>
                                                        <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2" role="list">
                                                            <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                                <div class="uitk-typelist-item-icon">
                                                                    <svg class="uitk-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" d="M19 3h-1V1h-2v2H8V1H6v2H5a2 2 0 0 0-1.99 2L3 19a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm0 5v11H5V8h14zm-7 2H7v5h5v-5z" clip-rule="evenodd"></path>
                                                                    </svg>
                                                                </div>
                                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Tue, Apr 2, 10:30am</div>
                                                            </li>
                                                            <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                                <div class="uitk-typelist-item-icon">
                                                                    <svg class="uitk-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" d="M20.23 8.58a1.5 1.5 0 0 1 1.84 1.06c.21.8-.26 1.62-1.07 1.85L4.43 15.92l-2.59-4.49 1.45-.39 1.97 1.54 4.97-1.33-4.14-7.17 1.93-.51 6.9 6.43 5.31-1.42zM21.5 19h-19v2h19v-2z" clip-rule="evenodd"></path>
                                                                    </svg>
                                                                </div>
                                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">LHR Airport
                                                                    <div>Northrop Rd London Heathrow Apt, London, GBR TW6 2QA</div>
                                                                </div>
                                                            </li>
                                                            <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                                <div class="uitk-typelist-item-icon">
                                                                    <svg class="uitk-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                                                    </svg>
                                                                </div>
                                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Hours of operation
                                                                    <div>5:00am - 11:59pm</div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Insurance" class="insurance-priming-card">
                                <div tabindex="-1">
                                    <div data-testid="insurance-priming-card">
                                        <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-highlight-theme">
                                            <div class="uitk-layout-flex">
                                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                                    <h2 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-blockend-">Get a rental car insurance plan</h2>
                                                    <div class="uitk-layout-flex">
                                                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                                            <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-margin-blockstart-two" role="list">
                                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                                    <div class="uitk-typelist-item-icon">
                                                                        <svg class="uitk-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"></path>
                                                                        </svg>
                                                                    </div>
                                                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme">Covers certain bumps, scratches, and other damage</div>
                                                                </li>
                                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                                    <div class="uitk-typelist-item-icon">
                                                                        <svg class="uitk-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"></path>
                                                                        </svg>
                                                                    </div>
                                                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme">Helps protect your rental vehicle in case of an accident or collision</div>
                                                                </li>
                                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                                    <div class="uitk-typelist-item-icon">
                                                                        <svg class="uitk-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"></path>
                                                                        </svg>
                                                                    </div>
                                                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme">Gives you access to 24/7 emergency travel assistance</div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr" />
                                                    <div class="uitk-text uitk-type-300 uitk-type-medium uitk-text-default-theme">Add the insurance plan to your rental car on the next step.</div>
                                                </div>
                                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-0">
                                                    <img class="uitk-illustration" alt="insurance" src="https://a.travel-assets.com/egds/illustrations/uds-default/shield__xsmall.svg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Policies section-3">
                                <div tabindex="-1"> 
                                    <div data-stid="important-info-card" data-testid="important-info-card" class="uitk-spacing uitk-spacing-margin-block-six">
                                        <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-spacing uitk-spacing-margin-blockstart-three uitk-card-has-primary-theme">
                                            <div>
                                                <h2 class="uitk-heading uitk-heading-5">Rental policies</h2>
                                                <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                                                   <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                        <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                        <span class="uitk-typelist-item-child">
                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">You will not be charged anything for the rental since the booking was risk-free.</div>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                                                    <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                        <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                        <span class="uitk-typelist-item-child">
                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Drivers under 25 or over 70 years of age may need to pay an extra fee.</div>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div class="uitk-spacing uitk-spacing-margin-blockstart-four">
                                                    <a href="/carsearch/rulesAndRestrictions?carOfferToken=AQh3XDzUb4Yaw4cMGaeYnjj7avjCnI7in9MF41jMKsvon9mFEdaoIxTjm-SaOL9s8pIseYOus6JdFtZqKk1UYEK9Kv6EDYZjmiVeqOdn_ZmPI&amp;useEgtpPath=true&amp;deviceOS=WebBrowser" target="_blank" class="uitk-link important-info-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">View rules and restrictions
                                                        <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inlinestart-one uitk-icon-small" aria-label="Opens a new tab" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <title id="opens-new-window-title">Opens a new tab</title>
                                                            <path fill-rule="evenodd" d="M5 5v14h14v-7h2v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7v2H5zm9 0V3h7v7h-2V6.41l-9.83 9.83-1.41-1.41L17.59 5H14z" clip-rule="evenodd"></path>
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="uitk-spacing uitk-spacing-margin-block-six"></div>
                                            <div data-stid="inclusion-card" data-testid="inclusion-card">
                                                <h2 class="uitk-heading uitk-heading-6">What's included</h2>
                                                <div class="uitk-text uitk-type-start uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">These are included in the total price of the car rental.</div>
                                            </div>
                                            <div data-stid="online-checkin-card" data-testid="online-checkin-card" class="extras-card">
                                                <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                                                    <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                        <div class="uitk-typelist-item-icon">
                                                            <svg class="uitk-icon uitk-icon-default-theme" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"></path>
                                                            </svg>
                                                        </div>
                                                        <div class="uitk-text uitk-type-400 uitk-type-medium uitk-text-default-theme">Minimal contact with option to check-in online</div>
                                                        <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                                                            <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                                <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                                <span class="uitk-typelist-item-child">
                                                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme">You will receive an email/a message from Budget to check-in online after the car has been reserved</div>
                                                                </span>
                                                            </li>
                                                            <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                                <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                                <span class="uitk-typelist-item-child">
                                                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme">No paper-work required during pick-up</div>
                                                                </span>
                                                            </li>
                                                            <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                                <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                                <span class="uitk-typelist-item-child">
                                                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme">Minimize contact and save time at the pick-up counter</div>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div id="Extras section-4">
                                <div tabindex="-1">
                                    <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-spacing uitk-spacing-margin-block-six uitk-card-has-primary-theme" data-stid="extras-card">
                                        <div class="extras-card">
                                            <h2 class="uitk-heading uitk-heading-5">Extras</h2>
                                            <div class="uitk-text uitk-type-start uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">Requests cannot be guaranteed as they are subject to availability. Payment due at pick-up.</div>
                                            <div class="uitk-spacing extras-card-accessories-container uitk-spacing-margin-blockstart-three">
                                                <fieldset class="uitk-checkbox-group">
                                                    <legend class="is-visually-hidden">Extras</legend>
                                                    <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                                        <input type="checkbox" name="BabyChildSeat" id="BabyChildSeat" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 extras-card-checkbox" value=""/>
                                                        <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                                            <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix extras-card-checkbox-label" for="BabyChildSeat">
                                                                <span class="uitk-checkbox-label-content">
                                                                    <div aria-hidden="false">Baby Child Seat</div>
                                                                </span>
                                                                <span class="uitk-checkbox-label-suffix">
                                                                    <div>
                                                                        <h4 class="uitk-heading uitk-type-end uitk-heading-6">$17</h4>
                                                                        <div class="uitk-text uitk-type-end uitk-text-spacing-half uitk-type-200 uitk-type-regular uitk-text-default-theme">per day</div>
                                                                    </div>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                                        <input type="checkbox" name="BoosterSeat" id="BoosterSeat" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 extras-card-checkbox" value=""/>
                                                        <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                                            <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix extras-card-checkbox-label" for="BoosterSeat">
                                                                <span class="uitk-checkbox-label-content">
                                                                    <div aria-hidden="false">Booster Seat</div>
                                                                </span>
                                                                <span class="uitk-checkbox-label-suffix">
                                                                    <div>
                                                                        <h4 class="uitk-heading uitk-type-end uitk-heading-6">$17</h4>
                                                                        <div class="uitk-text uitk-type-end uitk-text-spacing-half uitk-type-200 uitk-type-regular uitk-text-default-theme">per day</div>
                                                                    </div>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                                        <input type="checkbox" name="RoadsideAssistance" id="RoadsideAssistance" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 extras-card-checkbox" value=""/>
                                                        <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                                            <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix extras-card-checkbox-label" for="RoadsideAssistance">
                                                                <span class="uitk-checkbox-label-content">
                                                                    <div aria-hidden="false">Roadside Assistance</div>
                                                                </span>
                                                                <span class="uitk-checkbox-label-suffix">
                                                                    <div>
                                                                        <h4 class="uitk-heading uitk-type-end uitk-heading-6">$8</h4>
                                                                        <div class="uitk-text uitk-type-end uitk-text-spacing-half uitk-type-200 uitk-type-regular uitk-text-default-theme">per day</div>
                                                                    </div>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                                        <input type="checkbox" name="HandHeldNavigationSystem" id="HandHeldNavigationSystem" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 extras-card-checkbox" value=""/>
                                                        <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                                            <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix extras-card-checkbox-label" for="HandHeldNavigationSystem">
                                                                <span class="uitk-checkbox-label-content">
                                                                    <div aria-hidden="false">Handheld Navigation System</div>
                                                                </span>
                                                                <span class="uitk-checkbox-label-suffix">
                                                                    <div>
                                                                        <h4 class="uitk-heading uitk-type-end uitk-heading-6">$13</h4>
                                                                        <div class="uitk-text uitk-type-end uitk-text-spacing-half uitk-type-200 uitk-type-regular uitk-text-default-theme">per day</div>
                                                                    </div>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                                        <input type="checkbox" name="WifiAccess" id="WifiAccess" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 extras-card-checkbox" value=""/>
                                                        <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                                            <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix extras-card-checkbox-label" for="WifiAccess">
                                                                <span class="uitk-checkbox-label-content">
                                                                    <div aria-hidden="false">Wifi Access</div>
                                                                </span>
                                                                <span class="uitk-checkbox-label-suffix">
                                                                    <div>
                                                                        <h4 class="uitk-heading uitk-type-end uitk-heading-6">$13</h4>
                                                                        <div class="uitk-text uitk-type-end uitk-text-spacing-half uitk-type-200 uitk-type-regular uitk-text-default-theme">per day</div>
                                                                    </div>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                                        <input type="checkbox" name="BabyStroller" id="BabyStroller" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 extras-card-checkbox" value="" />
                                                        <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                                            <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix extras-card-checkbox-label" for="BabyStroller">
                                                                <span class="uitk-checkbox-label-content">
                                                                    <div aria-hidden="false">Baby Stroller</div>
                                                                </span>
                                                                <span class="uitk-checkbox-label-suffix">
                                                                    <div>
                                                                        <h4 class="uitk-heading uitk-type-end uitk-heading-6">$17</h4>
                                                                        <div class="uitk-text uitk-type-end uitk-text-spacing-half uitk-type-200 uitk-type-regular uitk-text-default-theme">per day</div>
                                                                    </div>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  <i class='awe-icon fa tc fa-check' aria-hidden='true'>
                    <FontAwesomeIcon icon={faLocation} />
                  </i>{' '}
                </div>
                <div className='col-md-4'>  
                    <div>
                        <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all price-summary-pair-message uitk-card-has-primary-theme">
                            <div data-stid="price-summary-pair-message" data-testid="price-summary-pair-message">
                                <div class="uitk-spacing uitk-spacing-padding-three">
                                    <div aria-hidden="true">
                                        <h2 class="uitk-subheading">
                                            <span class="uitk-text uitk-type-600 uitk-type-bold uitk-text-emphasis-theme">$51</span>
                                            &nbsp;<span class="uitk-text uitk-type-200 uitk-text-default-theme">per day</span>
                                        </h2>
                                    </div>
                                    <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                        <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-1 uitk-typelist-spacing" role="list">
                                            <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-1 uitk-typelist-item-indent" role="listitem">
                                                <div>
                                                    <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-positive-theme">Free cancellation</div>
                                                </div>
                                            </li>
                                            <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-1 uitk-typelist-item-indent" role="listitem">
                                                <div>
                                                    <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme">Pay at pick-up</div>
                                                </div>
                                            </li>
                                            <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-1 uitk-typelist-item-indent" role="listitem">
                                                <div>
                                                    <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme">Reserve without a credit card</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <hr class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-border-blockstart uitk-spacing-hr"/>
                                    <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-emphasis-theme uitk-spacing uitk-spacing-margin-blockend-two">Additional benefits with this car rental</div>
                                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                            <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"></path>
                                            </svg>
                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme">Online check-in</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                         </div>
                         <div class="uitk-spacing uitk-spacing-margin-block-three">
                            <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-spacing price-details uitk-spacing-padding-inline-six uitk-spacing-padding-block-six uitk-card-has-primary-theme">
                                <h2 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-padding-blockend-three">Price details</h2>
                                <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2" role="list">
                                    <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                        <div class="uitk-spacing uitk-spacing-padding-blockend-four">
                                            <h3 class="uitk-heading uitk-heading-7">Pay at pick-up</h3>
                                            <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2" role="list">
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                    <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                                            <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme uitk-layout-flex-item">Car rental fee x 1 day</div>
                                                            <div class="uitk-text uitk-type-end uitk-type-300 uitk-text-default-theme uitk-layout-flex-item">$50.85 </div>
                                                        </div>
                                                        <div class="uitk-break">
                                                            <div class="uitk-text uitk-type-200 uitk-text-default-theme">$50.85 per day</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                                    <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                                            <div data-stid="price-breakup-dialog" data-testid="price-breakup-dialog" class="uitk-layout-flex-item">
                                                                <div class="uitk-spacing price-breakup-charge uitk-spacing-padding-inlineend-six uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-left disclaimer-msg-link">
                                                                    <button class="disclaimer-msg-link">
                                                                        <span>Taxes and fees</span>
                                                                        <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inlinestart-one uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="uitk-text uitk-type-end uitk-type-300 uitk-text-default-theme uitk-layout-flex-item">$170.87 </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <hr class="uitk-spacing uitk-spacing-margin-block-two uitk-spacing-border-blockstart uitk-spacing-hr"/>
                                <div class="uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-spacing uitk-spacing-margin-blockstart-six">
                                    <h3 class="uitk-heading uitk-heading-6">Total</h3>
                                    <h3 class="uitk-heading uitk-type-end uitk-heading-6">$221.72</h3>
                                </div>
                                <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-spacing total-breakup uitk-spacing-margin-blockstart-three" role="list">
                                    <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                            <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme uitk-layout-flex-item full-width">Pay at pick-up</div>
                                            <div class="uitk-text uitk-type-end uitk-type-300 uitk-text-default-theme uitk-layout-flex-item full-width">$221.72</div>
                                        </div>
                                    </li>
                                    <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                            <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-default-theme uitk-layout-flex-item full-width">Pay now</div>
                                            <div class="uitk-text uitk-type-end uitk-type-300 uitk-text-default-theme uitk-layout-flex-item full-width">$0.00</div>
                                        </div>
                                    </li>
                                </ul>
                                <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                                    <button name="reserve-btn" aria-label="Reserve Compact SUV from Budget for $222 total" type="button" class="uitk-button uitk-button-large uitk-button-fullWidth uitk-button-has-text uitk-button-primary"  onClick={()=>navigate('/transfer-checkout')}>Reserve</button>
                                </div>
                            </div>
                        </div>
                        <div data-stid="deal-messaging-card" data-testid="deal-messaging-card">
                            <div class="uitk-messaging-card uitk-spacing uitk-spacing-margin-block-three imagelayout-left">
                                <div class="uitk-messaging-card-media" aria-hidden="true">
                                    <div aria-hidden="true">
                                        <div class="uitk-icon-spotlight uitk-icon-spotlight-info uitk-icon-spotlight-has-background">
                                            <svg class="uitk-icon uitk-icon-spotlight-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M21 8a2 2 0 0 1 2 2v2c0 .26-.05.5-.14.73l-3.02 7.05A1.99 1.99 0 0 1 18 21H9a2 2 0 0 1-2-2V9c0-.55.22-1.05.59-1.41L14.17 1l1.06 1.05c.39.39.46.87.41 1.38L14.69 8H21zM5 21H1V9h4v12z" clip-rule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <section class="uitk-messaging-card-section">
                                    <h3 class="uitk-messaging-card-section-header">Great deal</h3>
                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme">This car is $62 less than the average price of the Compact SUV category car on our website.</div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </section>
            <div class="uitk-spacing SimpleContainer">
                <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three">
                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                        <figure class="uitk-image uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-image-ratio-3-2 uitk-image-ratio figure-tree">
                            <div class="uitk-image-placeholder">
                                <img alt="" class="uitk-image-media" src="https://a.travel-assets.com/mad-service/footer/bnaBanners/BEX_Footer.jpg"/>
                            </div>
                        </figure>
                        <div class="uitk-card-content-section uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-tree">
                            <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-six">
                                <div role="region" aria-labelledby="marquee-title" class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-column-start width-q">
                                    <h2 class="uitk-heading uitk-heading-3" id="marquee-title">Go further with the Expedia app</h2>
                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-type-paragraph-300 uitk-spacing-padding-blockstart-two uitk-spacing-padding-inlineend-four">Save on select hotels and earn OneKeyCash on bookings in the app. Our app deals help you to save on trips so you can travel more and manage it all on the go.</div>
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-twelve uitk-spacing-padding-inlineend-four uitk-spacing-padding-blockend-two">Scan the QR code with your device camera and download our app</div>
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid switch">
                                        <div class="uitk-spacing uitk-spacing-padding-blockstart-six uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                            <a id="download-btn" name="download-btn" href="https://expe.app.link/?~customer_ad_set_name=EXPEDIA-US&amp;~customer_ad_name=BRAND.EXPEDIA.OWNED.BRANCH.DEFAULT.MAD&amp;~customer_campaign=BRAND&amp;~customer_placement=BEX-HP-MARQUEEDESKTOP&amp;~placement_id=Homepage&amp;%24canonical_url=https%3A%2F%2Fwww.expedia.com%2F&amp;%24desktop_url=https%3A%2F%2Fwww.expedia.com%2Fapp&amp;custom_web_attribute=add49b7a-17c6-412c-957e-78218a70f45a" class="uitk-button uitk-button-large uitk-button-fullWidth uitk-button-has-text uitk-button-as-link uitk-button-primary">Switch to the app</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start QR-code">
                                    <div class="uitk-spacing uitk-spacing-padding-inlinestart-four">
                                        <figure class="uitk-image uitk-image-ratio-1-1 uitk-image-ratio">
                                            <div class="uitk-image-placeholder">
                                                <img alt="QR code" class="uitk-image-media" src="https://a.travel-assets.com/mad-service/qr-code/footer_qr_hp/1_QR_SHEET_CONTROL_FOOTER_HP.png"/>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        </Layout>
      )}
      <div id="mybutton">
      <button  onClick={BookRoom} class="btn feedback  select-styling search-btn1 mt-2 detail-view-btn">Book Now</button>
      </div>
    </>
  )
}

export default TransferDetail
