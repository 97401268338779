import React, { useEffect, useState} from 'react';
import { Sidenav, Nav } from 'rsuite'
import DashboardIcon from '@rsuite/icons/legacy/Dashboard'
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle'
import Profile from '@rsuite/icons/legacy/Profile'
import SignOut from '@rsuite/icons/legacy/SignOut'
import FileUpload from '@rsuite/icons/legacy/FileUpload'
import Layout from '../../Components/Layout/Layout'
import { useSelector } from 'react-redux'
import DashboardPage from './DashboardPage'
import Settingage from './Settingage'
import Axios from 'axios';
import { ApiEndPoint } from '../../Components/GlobalData/GlobalData';
import IncomeStatement from './IncomeStatement';
import MakePayment from './MakePayment';
function Dashboard ({ onLogout }) {
  const baseurl = ApiEndPoint();
    const UserDetail = useSelector(state => state.hotels.UserLoginRes);
    const [showSetting, setShowSetting] = useState(false);
    const [makePayment, setMakePayment] = useState(false);
    const [tentitiveBookings, setTentitiveBookings] = useState([]);
    const [incomStatment, setIncomStatment] = useState(false);
    const [dashboardPage, setDashboardPage] = useState(true);
    const [activeKey, setActiveKey] = useState('1'); 
    const [invoiceList, setInvoiceList] = useState([]);
    const [dashboardData, setDashboardData] = useState([]);
    const [incomList, setIncomList] = useState([]);
    const handleNavItemSelect = (eventKey) => {
      setActiveKey(eventKey); 
      if (eventKey === '3') {
        setIncomStatment(false);
        setDashboardPage(false);
        setMakePayment(false);
        setShowSetting(true); 
      } else if(eventKey === '2') {
        setShowSetting(false); 
        setDashboardPage(false);
        setMakePayment(false);
        setIncomStatment(true);
      } else if(eventKey === '1') {
        setShowSetting(false); 
        setIncomStatment(false);
        setDashboardPage(true);
        setMakePayment(false);
        
      }else if(eventKey === '5') {
        setShowSetting(false); 
        setIncomStatment(false);
        setDashboardPage(false);
        setMakePayment(true);
        
      }
    };
    useEffect(() => {
      GetUserBooking();
    }, []);
    const logout = () => {
        onLogout()
      };
      const GetUserBooking = async () => {
        const data = {
          token: UserDetail.token,
          b2b_agent_id: UserDetail.id,
        };
    
        try {
          const response = await Axios.post(
            baseurl + '/api/view_customer_hotel_booking',
            data
          );
          console.log(response)
          if (response.data.message === 'success') {
            setTentitiveBookings(response.data.tentative_Hotel_Bookings)
            setInvoiceList(response.data.inv_details);
            const mergedArray = [...response.data.hotels_bookings.map(obj => ({ ...obj, domain_name: 'Hotel Booking' })),
                     ...response.data.invoice_Bookings.map(obj => ({ ...obj, domain_name: 'Inovice Booking' })),
                     ...response.data.transfer_Bookings.map(obj => ({ ...obj, domain_name: 'Transfer Booking' }))];
                     console.log(mergedArray)
                     setDashboardData(mergedArray);
                     const confirmlist=response.data.inv_details.filter(item=> item.status==='CONFIRMED');
            setIncomList(confirmlist);
          }
        } catch (error) {
          console.log(error);
          console.error('Error:', error);
        }
      };
    
  return (
    <>
      <Layout logout={onLogout}>
        <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-3 cold-md-3'>
                <div className='text-center pt-3 pb-3'>
                    <h4 style={{color:'#000'}}>{UserDetail.first_name} {UserDetail.last_name}</h4>
                    <p>{UserDetail.phone_no}</p>
                    <p>{UserDetail.email}</p>
                </div>
            <Sidenav defaultOpenKeys={['3', '4']}>
            <Sidenav.Body>
              <Nav activeKey={activeKey} onSelect={handleNavItemSelect}>
                <Nav.Item eventKey='1' icon={<DashboardIcon />}>
                  Dashboard
                </Nav.Item>
                <Nav.Item eventKey='2' icon={<Profile />}>
                  Income Statement
                </Nav.Item>
                <Nav.Item eventKey='5' icon={<FileUpload />}>
                  Make Payment
                </Nav.Item>
                <Nav.Item eventKey='3' icon={<GearCircleIcon />}>
                  Setting
                </Nav.Item>
                <Nav.Item eventKey='4' onClick={logout} icon={<SignOut />}>
                  Logout
                </Nav.Item>
              </Nav>
            </Sidenav.Body>
          </Sidenav>
            </div>
            <div className='col-lg-9 cold-md-9'>
              {dashboardPage && (
                <DashboardPage invoiceListData={dashboardData} />
              )}
              {showSetting && (
                 <Settingage userData={UserDetail}/> 
              )}
            {incomStatment && (
                 <IncomeStatement invoiceListData={incomList}/> 
              )}
              {makePayment && (
                 <MakePayment tentativebookins={tentitiveBookings}/> 
              )}
          
            </div>
        </div>
        </div>
      </Layout>
    </>
  )
}

export default Dashboard
