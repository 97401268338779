import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer2";
import Navbar2 from "../Navbar/Navbar2";
function Layout({ children,logout }){
    return(
        <>
            <Navbar goLogout={logout}/>
            {children}
            <Footer />
        </>
    )
}

export default Layout;