import React, { useState } from 'react'
import { DatePicker, Uploader } from 'rsuite'
import CameraRetroIcon from '@rsuite/icons/legacy/CameraRetro'
import { ToastContainer, toast } from 'react-toastify'
import { SelectPicker, Stack } from 'rsuite';
import Axios from 'axios';
import { ApiEndPoint,Hotelapitoken } from '../../Components/GlobalData/GlobalData';
import moment from 'moment';
import Loading from '../../Components/Loading/Loader';
import { useSelector } from 'react-redux';
function MakePayment ({tentativebookins}) {
  const [transectionno, setTransectionno] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [date, setDate] = useState(new Date());
  const [isloading, setLoading] = useState(false);
  const [imageBase64, setImageBase64] = useState('');
  const [fileList, setFileList] = useState([]);
  const baseurl=ApiEndPoint();
  const UserDetail = useSelector(state => state.hotels.UserLoginRes);
  const data = tentativebookins.map(
    item => ({ label: item.invoice_no+'-'+item.lead_passenger, value: item.invoice_no})
  );
  const handleChange = Selectedimg => {
    if (Selectedimg.length > 1) {
      const latestFile = Selectedimg.slice(-1)
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result; // Get the Base64 part only
        setImageBase64(base64);
      };
      reader.readAsDataURL(latestFile[0].blobFile);
      setFileList(latestFile)
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result; // Get the Base64 part only
        setImageBase64(base64);
      };
      reader.readAsDataURL(Selectedimg[0].blobFile);
      setFileList(Selectedimg)
    }
  };
  const handledetailchange = event => {
    setTransectionno(event.target.value)
  };
  const handlechangeInvoicenumber = value => {
    setInvoiceNumber(value)
  };
  const handledatechange = value => {
    setDate(value)
  };
  
  const SubmitData=async()=>{
    if(invoiceNumber===null){
      toast.error('Please Select Invoice Number.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }else if(date===null){
      toast.error('Please Select Date.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }else if(transectionno===''){
      toast.error('Please Enter Transection Number.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }else if(fileList.length===0){
      toast.error('Please Select the Slip Image.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }
    setLoading(true);
    var req_data={
      'b2b_agent_id':UserDetail.id,
      'invoice_No':invoiceNumber,
      'payment_Date':moment(date).format('DD-MM-YYYY'),
      'transaction_No':transectionno,
      'payment_Image':JSON.stringify(imageBase64),
    }
    try {
      const response = await Axios.post(
        baseurl + '/api/hotels_Make_Payemnt',
        req_data
      );
      setLoading(false);
      if(response.data.status==='success'){
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
        clearForm();
      }else{
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
      
    } catch (error) {
      setLoading(false);
      console.log(error);
      console.error('Error:', error);
    }
  }
  const clearForm=()=>{
    setInvoiceNumber(null);
    setTransectionno('');
    setFileList([]);
    setDate(new Date());
  }
  return (
    <>
    {isloading && (<Loading/>)}
    <ToastContainer/>
      <div className='agent-head'>
        <h5>Please upload payment details.</h5>
      </div>
      <div className='row'>
        <div class='form-group col-12 col-sm-12 col-md-6 mt-3'>
          <label className='fw-bold'>Invoice Number*</label>
            <SelectPicker onChange={handlechangeInvoicenumber} value={invoiceNumber}  name='invoicenumber' className='mt-2' placeholder='Select Invoice' data={data} style={{ width: '100%' }}/>
          
        </div>
        <div class='form-group col-12 col-sm-12 col-md-6 mt-3'>
          <label className='fw-bold'>Date*</label>
          <DatePicker
            format='dd-MM-yyyy'
            onChange={handledatechange}
            value={date}
            className='mt-2 d-block'
            oneTap
          />
        </div>
        <div class='form-group col-12 col-sm-12 col-md-12 mt-3'>
          <label className='fw-bold '>Transaction Number*</label>
          <input
            type='text'
            value={transectionno}
            onChange={handledetailchange}
            name='transectionno'
            class='form-control mt-2'
            placeholder='Enter Transaction Number'
          />
        </div>
        <div class='form-group col-12 col-sm-12 col-md-12 mt-3'>
          <Uploader
            accept='.png, .jpg, .jpeg'
            draggable
            fileList={fileList}
            onChange={handleChange}
            multiple={false}
            listType='picture'
            autoUpload={false}
            action='//jsonplaceholder.typicode.com/posts/'
          >
            <button>
              <CameraRetroIcon />
            </button>
          </Uploader>
        </div>
      </div>
      <div className='text-center mt-3'>
        <button className='btn btn-success' onClick={SubmitData}>Submit Detail</button>
      </div>
    </>
  )
}
export default MakePayment
