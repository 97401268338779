import React, { useState, useEffect } from 'react'
import FlightDetail from './FlightCard'
import Faqs from '../../Components/FAQs/FAQs'
import Layout from '../../Components/Layout/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faPhone,
  faMapMarker,
  faPersonCirclePlus,
  faPersonCircleMinus,
  faCalendar,
  faMoon,
  faClipboardCheck,
  faBus,
  faBuildingCircleCheck,
  faPlaneDeparture,
  faPlaneArrival,
  faBed,
  faClock,
  faDollar,
  faHeadphones,
  faStar,
  faLock,
  faMailBulk,
  faEnvelope,
  faCalendarCheck,
  faCalendarMinus
} from '@fortawesome/free-solid-svg-icons'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import kaba from '../../Images/Packages/kabapic.jpg';
import busimage from '../../Images/Packages/Logo/bus.jpg' 
import { CustomerDomainName } from '../../Components/GlobalData/GlobalData'
import moment from 'moment'
import Images from '../../Components/Images/images'
import iternary from '../../Images/Packages/iternary.webp'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
function ViewDeail ({ onLogout }) {
  var url = CustomerDomainName();
  const tourDetail = useSelector(state => state.hotels.viewtourdetail);
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const tourlocation = JSON.parse(tourDetail.tours.tour_location);
  const transportationdetail = JSON.parse(
    tourDetail.tours.transportation_details
  )
  const Itinerarydetail = JSON.parse(tourDetail.tours.Itinerary_details)
  var Itinerarydetailmore=[]
  if(tourDetail.tours.tour_itinerary_details_1 !== null){
  Itinerarydetailmore = JSON.parse(
    tourDetail.tours.tour_itinerary_details_1
  )
}
  const accomodationdetail = JSON.parse(tourDetail.tours.accomodation_details)
  const accomodationdetailmore = JSON.parse(
    tourDetail.tours.accomodation_details_more
  )
  const transportationdetailmore = tourDetail.tours.transportation_details_more
  ? JSON.parse(tourDetail.tours.transportation_details_more)
  : [];
  const images = JSON.parse(tourDetail.tours.gallery_images)
  const [opencartmodel, setOpencartmodel] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [activeIndex, setActiveIndex] = useState(null)
  const [cancelModal, setCancelModal] = useState(false)
  const [minValue, setMinValue] = useState({price:0,currency:''})
  const [modalAdultRoom, setModalAdultRoom] = useState({double:'',triple:'',quad:''});
  const [modalAdultAdult, setModalAdultAdult] = useState({double:'',triple:'',quad:''});
  const [modalAdultTotal, setModalAdultTotal] = useState({double:0,triple:0,quad:0});

  const [activeButton, setActiveButton] = useState(null)

  // const handleAdultRoomChange = (event, price) => {
  //   var { name, value } = event.target;

  //   if(name==='double_rooms'){
  //     setModalAdultRoom((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //      double: value
  //     }));
  //     var adult=value*2;
  //     setModalAdultAdult((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //       double: adult
  //     }));

  //     var p=adult*price;
  //     setModalAdultTotal((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //       double: p
  //     }));

  //   }
  // };
  // const handleAdultAdultChange = (event, price) => {
  //   var { name, value } = event.target;

  //   if(name==='double_adult'){
  //     setModalAdultAdult((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //       double: value
  //     }));

  //     var p=value*price;
  //     setModalAdultTotal((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //       double: p
  //     }));

  //   }
  // };
  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  const show = index => {
    const buttons = document.getElementsByClassName('accordion')
    const panel = buttons[index].nextElementSibling
    buttons[index].classList.toggle('active-2')

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px'
    }

    // Remove "active" class from other buttons
    for (let i = 0; i < buttons.length; i++) {
      if (i !== index) {
        buttons[i].classList.remove('active-2')
        buttons[i].nextElementSibling.style.maxHeight = null
      }
    }
  }
  useEffect(() => {
    calcalutemin();
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);

  const toggleModal2=()=>{
    setCancelModal(!cancelModal)
  }
const calcalutemin=()=>{
  var min_value = Math.min(tourDetail.tours.double_grand_total_amount, tourDetail.tours.triple_grand_total_amount, tourDetail.tours.quad_grand_total_amount);
  if (minValue.price !== min_value) {
   setMinValue({price:min_value,currency:tourDetail.tours.currency_symbol});
 }

};
  return (
    <>
      <Layout logout={onLogout}>
          {/* <div className="row mt-2 p-3 hotel-top  modify-flight-search"> <PackageSearch/></div> */}
        <div className='container-fluid px-4'>
          <Images data={images} setting={1} />
          <div class="uitk-card-content-section uitk-card-content-section-border-block-end uitk-card uitk-card-roundcorner-all custom-css-flat-border-top custom-css-flat-border-bottom uitk-card-has-primary-theme" data-stid="uitk-card-content-section-navbar" style={{position:'sticky'}}>
            <div class="uitk-tabs-container">
              <ul class="uitk-tabs uitk-tabs-natural background-primary uitk-tabs-jumplink uitk-tabs-with-border" role="list">
                <li role="listitem" className={activeButton === 'section-1' ? 'current' : 'uitk-tab'}>
                  <a href='#section-1' onClick={() => handleButtonClick('section-1')} class="uitk-tab-anchor"
                      aria-current="true" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text">Overview</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-2' ? 'current' : 'uitk-tab'}>
                  <a href='#section-2' onClick={() => handleButtonClick('section-2')} 
                    className="uitk-tab-anchor" aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text"> Schedule</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-3' ? 'current' : 'uitk-tab'}>
                  <a href='#section-3' onClick={() => handleButtonClick('section-3')} className="uitk-tab-anchor"
                      aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text">Flights</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-4' ? 'current' : 'uitk-tab'}>
                  <a href='#section-4' onClick={() => handleButtonClick('section-4')} className="uitk-tab-anchor"
                      aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text"> Accomodation</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-5' ? 'current' : 'uitk-tab'}>
                  <a href='#section-5'  onClick={() => handleButtonClick('section-5')} className="uitk-tab-anchor"
                      aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text">Transportion</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-6' ? 'current' : 'uitk-tab'}>
                  <a href='#section-6'  onClick={() => handleButtonClick('section-6')} className="uitk-tab-anchor"
                      aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text"> Visa</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-7' ? 'current' : 'uitk-tab'}>
                  <a href='#section-7'  onClick={() => handleButtonClick('section-7')} className="uitk-tab-anchor"
                      aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text"> FAQ</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
              </ul>
            </div>
            <div class="uitk-layout-position uitk-layout-position-right-zero uitk-layout-position-top-zero uitk-layout-position-bottom-zero uitk-layout-position-absolute mt-1"> 
              <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-justify-content-flex-end uitk-spacing">
                <div class="uitk-layout-flex uitk-layout-flex-align-content-center uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-item uitk-layout-flex-item-flex-grow-0 uitk-spacing uitk-spacing-padding-inlineend-three">
                  <div data-stid="navbar-quick-reserve-button">
                    <button data-stid="sticky-button" type="button" class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-primary">Price Start From : {minValue.currency} {minValue.price}</button>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
        <div className='container-fluid px-5'>
          <div className='row'>
            <div class='col-sm-12 col-md-12'>
              <section id='section-1' className='p-3 hotel-checkout-shadow rounded-4 mt-2' style={{color:'black'}}>
                <div class='row'>
                  <div class='col-md-12'>
                    <div class='product-title'>
                      <h2 className='view-detail-title'>{tourDetail.tours.title}</h2>
                    </div>
                    <div class='product-address'>
                      <span>
                        {' '}
                        <i class='fa-solid tc fa-location-dot'>
                          <FontAwesomeIcon icon={faMapMarker} />
                        </i>
                        &nbsp;
                        {tourlocation.map((location, index) => (
                          <span key={index}>
                            {location}
                            {index !== tourlocation.length - 1 ? ' ' : ''}
                          </span>
                        ))}
                        &nbsp;
                        <i class='fa-solid tc fa-phone'>
                          <FontAwesomeIcon icon={faPhone} />
                        </i>
                        &nbsp; 0121 777 2522
                      </span>
                    </div>
                    <p class='mt-2 mb-2'>{tourDetail.tours.content}</p>
                  </div>
                  <div class='col-md-6'>
                    <h6>
                      {' '}
                      <i class='fa-solid fa-person-circle-plus'>
                        {' '}
                        <FontAwesomeIcon icon={faPersonCirclePlus} />
                      </i>{' '}
                      Whats Included?
                    </h6>
                    <p></p>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: tourDetail.tours.whats_included
                        }}
                      ></span>
                      <br />
                    </p>
                    <p></p>
                  </div>
                  <div class='col-md-6'>
                    <h6>
                      <i class='fa-solid fa-person-circle-minus'>
                        <FontAwesomeIcon icon={faPersonCircleMinus} />
                      </i>{' '}
                      Whats Excluded?
                    </h6>
                    <p></p>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: tourDetail.tours.whats_excluded
                        }}
                      ></span>
                      <br />
                    </p>
                    <p></p>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-6 col-md-4 col-sm-6 '>
                    <div class='item'>
                      <h6> Departure Date</h6>
                      <p>
                        <i class='fa tc fa-calendar' aria-hidden='true'>
                          <FontAwesomeIcon icon={faCalendar} />
                        </i>{' '}
                        {moment(tourDetail.tours.start_date).format(
                          'DD-MM-YYYY'
                        )}
                      </p>
                    </div>
                  </div>
                  <div class='col-6 col-md-4 col-sm-6 '>
                    <div class='item'>
                      <h6> Return Date</h6>
                      <p>
                        <i class='fa tc fa-calendar' aria-hidden='true'>
                          <FontAwesomeIcon icon={faCalendar} />
                        </i>{' '}
                        {moment(tourDetail.tours.end_date).format('DD-MM-YYYY')}
                      </p>
                    </div>
                  </div>
                  <div class='col-6 col-md-4 col-sm-6 '>
                    <div class='item'>
                      <h6>Time length</h6>
                      <p>
                        <i class='fa-regular tc fa-moon'>
                          {' '}
                          <FontAwesomeIcon icon={faMoon} />
                        </i>{' '}
                        {tourDetail.tours.time_duration} Nights
                      </p>
                    </div>
                  </div>
                  <div class='col-6 col-md-4 col-sm-6 '>
                    <div class='item'>
                      <h6>Category</h6>
                      <p>
                        <i class='fa-solid tc fa-clipboard-check'>
                          <FontAwesomeIcon icon={faClipboardCheck} />
                        </i>{' '}
                      </p>
                    </div>
                  </div>
                  <div class='col-6 col-md-4 col-sm-6 '>
                    <div class='item'>
                      <h6>Transport included</h6>
                      <p>
                        <i class='fa fa-bus tc' aria-hidden='true'>
                          <FontAwesomeIcon icon={faBus} />
                        </i>
                        {transportationdetail[0].transportation_vehicle_type}
                      </p>
                    </div>
                  </div>
                  <div class='col-6 col-md-4 col-sm-6 '>
                    <div class='item'>
                      <h6>Destinations</h6>
                      <p title='Makkah, Madina'>
                        <i class='fa-solid tc fa-building-circle-check'>
                          {' '}
                          <FontAwesomeIcon icon={faBuildingCircleCheck} />
                        </i>
                        {tourlocation.map((location, index) => (
                          <span key={index}>
                            {location},
                            {index !== tourlocation.length - 1 ? ' ' : ''}
                          </span>
                        ))}
                      </p>
                    </div>
                  </div>
                  <div class='col-12 col-md-12 col-sm-12 gap-2 d-flex mt-4'>
                    <button onClick={toggleModal2} className='btn select-styling detail-view-btn search-btn1'>
                      Cancellation Policy ?
                    </button>
                    <button className='btn select-styling detail-view-btn search-btn1'>
                    Send Email Inquiry
                    </button>
                  </div>
                </div>
                <table class='ticket-price mt-5'>
                  <thead>
                    <tr className='text-center ticket-size'>
                      <th class='adult p-2'>
                        <span>Double Price</span>
                      </th>
                      <th class='adult tr-border'>
                        <span>Triple Price</span>
                      </th>

                      <th class='kid'>
                        <span>Quad Price</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr className='text-center tc table-border'>
                      <td class='adult'>
                        <span class='amount double fw-bold'>
                          {tourDetail.tours.currency_symbol}{' '}
                          {tourDetail.tours.double_grand_total_amount}
                        </span>
                      </td>
                      <td class='adult tr-border'>
                        <span class='amount fw-bold'>
                        {tourDetail.tours.currency_symbol}{' '}
                          {tourDetail.tours.triple_grand_total_amount}
                        </span>
                      </td>
                      <td class='kid'>
                        <span class='amount fw-bold'>
                        {tourDetail.tours.currency_symbol}{' '}
                          {tourDetail.tours.quad_grand_total_amount}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
              <section id='section-2' className='hotel-checkout-shadow rounded-4 mb-3 mt-3 p-3'>
                <h4 className='view-detail-heading '>Itinerary Schedule </h4>
                <div className='row mt-5'>
                  <div>
                <button
                        className={`accordion  ${activeIndex === 0 ? 'active' : ''}`}
                        onClick={() => show(0)}
                      >
                        {Itinerarydetail[0].Itinerary_title}:{' '}
                        {Itinerarydetail[0].Itinerary_content}
                      </button>
                      <div className='panel'>
                        <p>{Itinerarydetail[0].Itinerary_city}</p>
                      </div>
                      {Itinerarydetailmore.map((item, index) => (
                        <div key={index}>
                          <button
                            className={`accordion  ${
                              activeIndex === index + 1 ? 'active' : ''
                            }`}
                            onClick={() => show(index + 1)}
                          >
                            {item.more_Itinerary_title} : {item.more_Itinerary_city}
                          </button>
                          <div class='panel'>
                            <p>{item.more_Itinerary_content}</p>
                          </div>
                        </div>
                      ))}
                      </div>
                </div>
              </section>
              <section id='section-3'>
                <h4 className='view-detail-heading mb-2'>Flight Details</h4>
                <FlightDetail />
              </section>
              <section id='section-4' className='hotel-detail-padding hotel-checkout-shadow rounded-4 p-3 my-3'>
                {accomodationdetail.map((item, index) => {
                  const hotels = accomodationdetailmore.filter(
                    x => x.more_hotel_city.toUpperCase() === item.hotel_city_name.toUpperCase()
                  )
                  return (
                    <div key={index} class='hotel-details-top'>
                      <h4 className='view-detail-heading mt-1 pt-1'>
                        {item.hotel_city_name} Hotel Details
                      </h4>
                      <div class='row mt-4'>
                        <div class='col-sm-12 col-md-3'>
                          <img
                            src={
                              url +
                              '/public/uploads/package_imgs/' +
                              item.accomodation_image[0]
                            }
                            alt=''
                          />
                        </div>
                        <div class='col-sm-12 col-md-5'>
                          <h5>{item.acc_hotel_name}</h5>
                          <ul>
                            <li className='mt-1'>
                              <i class='fa-solid fa-bed'>
                                <FontAwesomeIcon icon={faBed} />
                              </i>{' '}
                              Room Type: {item.acc_type}
                              {hotels.map((a, index) => (
                                <span key={index}>/{a.more_acc_type} </span>
                              ))}
                              <span></span>
                            </li>
                            <li className='mt-1'>
                              {' '}
                              <i class='fa-solid fa-bed'>
                                <FontAwesomeIcon icon={faCalendarCheck} />
                              </i>{' '}
                              Check In : {moment(item.acc_check_in).format('DD-MM-YYYY')}
                            </li>
                            <li className='mt-1'>
                              {' '}
                              <i class='fa-solid fa-bed'>
                                <FontAwesomeIcon icon={faCalendarMinus} />
                              </i>{' '}
                              Check Out : {moment(item.acc_check_out).format('DD-MM-YYYY')}
                            </li>
                            <li className='mt-1'>
                              {' '}
                              <i class='fa-solid fa-bed'>
                                <FontAwesomeIcon icon={faMoon} />
                              </i>{' '}
                              No Of Nights : {item.acc_no_of_nightst}
                            </li>
                          </ul>
                        </div>
                        <div class='col-sm-12 col-md-4'>
                          <h5>Room Amenities </h5>
                          <p>{item.hotel_whats_included}</p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </section>
              <section id='section-5' className='hotel-checkout-shadow rounded-4 p-3'>
                <h4 className='view-detail-heading '>Transfer Details</h4>
                <div className='row mt-5'>
                  <div className='col-sm-6 col-md-3 text-center'>
                    <div class='image-thumb'>
                      <img
                        src={busimage}
                        alt=''
                        height='100'
                      />
                    </div>
                    <div class='tr-vehicle-name'>
                      <span>
                        Vehicle:{' '}
                        {transportationdetail[0].transportation_vehicle_type}
                      </span>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-3 text-center item-from'>
                    <div class=''>
                      <h3 className='tc' style={{ fontSize: '1.1rem' }}>
                        Pickup Location
                      </h3>
                      <h6 style={{ fontSize: '1rem' }}>
                        {
                          transportationdetail[0]
                            .transportation_pick_up_location
                        }
                      </h6>
                      <h6 style={{ fontSize: '.8rem' }}>
                        Pickup Date :{' '}
                        {moment(
                          transportationdetail[0].transportation_pick_up_date
                        ).format('DD-MM-YYYY')}{' '}
                      </h6>
                      <h6 style={{ fontSize: '.8rem' }}>
                        Pickup Date :{' '}
                        {moment(
                          transportationdetail[0].transportation_pick_up_date
                        ).format('LT')}{' '}
                      </h6>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-3 item-from'>
                    <div class='item-time'>
                      <i class='fa fa-clock-o'>
                        <FontAwesomeIcon icon={faClock} />
                      </i>
                      <span>
                        {transportationdetail[0].transportation_total_Time}
                      </span>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-3 text-center item-from'>
                    <div class=''>
                      <h3 className='tc' style={{ fontSize: '1.1rem' }}>
                        Drop off Location
                      </h3>
                      <h6 style={{ fontSize: '1rem' }}>
                        {
                          transportationdetail[0]
                            .transportation_drop_off_location
                        }
                      </h6>
                      <h6 style={{ fontSize: '.8rem' }}>
                        Drop off Date :{' '}
                        {moment(
                          transportationdetail[0].transportation_drop_of_date
                        ).format('DD-MM-YYYY')}{' '}
                      </h6>
                      <h6 style={{ fontSize: '.8rem' }}>
                        Drop off Time :{' '}
                        {moment(
                          transportationdetail[0].transportation_drop_of_date
                        ).format('LT')}{' '}
                      </h6>
                    </div>
                  </div>
                </div>
                {transportationdetailmore.map((item, index) => (
                  <div
                    key={index}
                    className='row  mt-4'
                    style={{ borderTop: '2px solid #8080804d' }}
                  >
                    <div className='col-sm-6 col-md-3 text-center'>
                      <div class='image-thumb'>
                        <img
                          src='https://demo2.synchronousdigital.com/public/admin_package/frontend/images/bus.jpg'
                          alt=''
                          height='100'
                        />
                      </div>
                      <div class='tr-vehicle-name'>
                        <span>
                          Vehicle:{' '}
                          {transportationdetail[0].transportation_vehicle_type}
                        </span>
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-3 text-center item-from'>
                      <div class=''>
                        <h3 className='tc' style={{ fontSize: '1.1rem' }}>
                          Pickup Location
                        </h3>
                        <h6 style={{ fontSize: '1rem' }}>
                          {item.more_transportation_pick_up_location}
                        </h6>
                        <h6 style={{ fontSize: '.8rem' }}>
                          Pickup Date :{' '}
                          {moment(item.more_transportation_pick_up_date).format(
                            'DD-MM-YYYY'
                          )}{' '}
                        </h6>
                        <h6 style={{ fontSize: '.8rem' }}>
                          Pickup Date :{' '}
                          {moment(item.more_transportation_pick_up_date).format(
                            'LT'
                          )}{' '}
                        </h6>
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-3 item-from'>
                      <div class='item-time'>
                        <i class='fa fa-clock-o'>
                          <FontAwesomeIcon icon={faClock} />
                        </i>
                        <span>{item.more_transportation_total_Time}</span>
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-3 text-center item-from'>
                      <div class=''>
                        <h3 className='tc' style={{ fontSize: '1.1rem' }}>
                          Drop off Location
                        </h3>
                        <h6 style={{ fontSize: '1rem' }}>
                          {item.more_transportation_drop_off_location}
                        </h6>
                        <h6 style={{ fontSize: '.8rem' }}>
                          Drop off Date :{' '}
                          {moment(item.more_transportation_drop_of_date).format(
                            'DD-MM-YYYY'
                          )}{' '}
                        </h6>
                        <h6 style={{ fontSize: '.8rem' }}>
                          Drop off Time :{' '}
                          {moment(item.more_transportation_drop_of_date).format(
                            'LT'
                          )}{' '}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))}
              </section>
              <section id='section-6'>
                <div className='row mt-5 hotel-checkout-shadow mx-2 rounded-4 pb-3'>
                  <div className='col-sm-6 col-4 col-md-4 item-from'>
                    <h4 className='px-3' style={{color:'black'}}>Visa Details</h4>
                  </div>
                  <div className='col-sm-6 col-8 col-md-3 text-center item-from'>
                    <div class='image-thumb'>
                      <img
                        src={
                          url +
                          '/public/uploads/package_imgs/' +
                          tourDetail.tours.visa_image
                        }
                        alt=''
                        height='100'
                      />
                    </div>
                    <div class=''>
                      <h6 style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                        Visa Type
                      </h6>
                      <h6 style={{ fontSize: '1rem', color: 'gray' }}>
                        {tourDetail.tours.visa_type}
                      </h6>
                    </div>
                  </div>
                  <div className='col-sm-12 col-8 col-md-5 text-justify item-from'>
                    <h6 style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                      Visa Requirements
                    </h6>
                    <h6 style={{ fontSize: '.8rem', color: 'gray' }}>
                      {/* {tourDetail.tours.visa_detials} */}
                    </h6>
                  </div>
                </div>
              </section>
              {/* <div id='section-2' data-stid="cruise-itinerary" tabindex="-1">
                <div class="mb-4 uitk-card uitk-card-roundcorner-all uitk-spacing uitk-spacing-margin-blockstart-six uitk-card-has-primary-theme hotel-checkout-shadow">
                  <h2 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-padding-four" tabindex="-1">Itinerary</h2>
                  <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-rows uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-four uitk-iternary">
                    <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-row-start uitk-layout-grid-item-has-row-end uitk-iternary1">
                      <div>
                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-iternary1-ol">
                          <div>
                            <ol class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 px-0" role="list">
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 1</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">Zeebrugge</div>
                                <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">Belgium</div>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">6:00pm</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true">
                                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-padding-inlineend-half uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                  </svg>6:00pm
                                </div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 2</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">Le Havre</div>
                                <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">France</div>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">9:00am to 9:00pm</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true">
                                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-padding-inlineend-half uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                  </svg>9:00am — 9:00pm
                                </div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 3</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">Southampton</div>
                                <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">United Kingdom</div>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">7:00am to 8:00pm</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true">
                                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-padding-inlineend-half uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                  </svg>7:00am — 8:00pm
                                </div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 4</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">At Sea</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true"></div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 5</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">Hamburg</div>
                                <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">Germany</div>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">7:00am to 9:00pm</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true">
                                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-padding-inlineend-half uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                  </svg>7:00am — 9:00pm
                                </div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 6</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">At Sea</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true"></div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 7</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">Rotterdam</div>
                                <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">Netherlands</div>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">8:00am to 8:00pm</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true">
                                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-padding-inlineend-half uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                  </svg>8:00am — 8:00pm
                                </div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 8</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">Zeebrugge</div>
                                <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">Belgium</div>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">7:00am</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true">
                                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-padding-inlineend-half uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                  </svg>7:00am
                                </div>
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-column-start uitk-iternary2">
                      <div class="uitk-gallery-carousel">
                        <h3 class="is-visually-hidden">7 night Europe</h3>
                        <div class="uitk-gallery-carousel-items ratio-16-9">
                          <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                            <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-16-9 uitk-image-ratio">
                              <div class="uitk-image-placeholder uitk-image-placeholder-image">
                                <img alt="Zeebrugge, Belgium" class="uitk-image-media" src={iternary} data-loaded="false"/>
                              </div>
                            </figure>
                          </div>
                          <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                            <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-16-9 uitk-image-ratio">
                              <div class="uitk-image-placeholder">
                                <img alt="Zeebrugge, Belgium" class="uitk-image-media" src={iternary}/>
                              </div>
                              <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link">
                                <span class="is-visually-hidden">Zeebrugge, Belgium</span>
                              </button>
                            </figure>
                          </div>
                          <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                            <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-16-9 uitk-image-ratio">
                              <div class="uitk-image-placeholder uitk-image-placeholder-image">
                                <img alt="Le Havre which includes a city and general coastal views" class="uitk-image-media" src={iternary} data-loaded="false"/>
                              </div>
                            </figure>
                          </div>
                          <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                            <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-16-9 uitk-image-ratio">
                              <div class="uitk-image-placeholder uitk-image-placeholder-image">
                                <img alt="Southampton featuring heritage architecture, chateau or palace and heritage elements" class="uitk-image-media" src={iternary} data-loaded="false"/>
                              </div>
                            </figure>
                          </div>
                        </div>
                        <div class="uitk-gallery-carousel-paging-controls">
                          <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-prev uitk-button-paging uitk-button-paging-overlay">
                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Previous item" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <title id="prev-button-sailing-details-carousel-title">Previous item</title>
                              <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                            </svg>
                          </button>
                          <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-next uitk-button-paging uitk-button-paging-overlay">
                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Next item" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <title id="next-button-sailing-details-carousel-title">Next item</title>
                              <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                            </svg>
                          </button>
                        </div>
                        <div class="uitk-scrim uitk-gallery-carousel-scrim fade-bottom">
                          <div class="uitk-gallery-carousel-footer">
                            <div class="uitk-gallery-carousel-footer-media-caption">Zeebrugge, Belgium</div>
                            <div class="uitk-action-buttons">
                              <button type="button" class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-overlay uitk-gallery-button">
                                <svg class="uitk-icon uitk-icon-leading" aria-label="Show all 6 items" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <title id="photo_library-sailing-details-carousel-title">Show all 6 items</title>
                                  <path fill-rule="evenodd" d="M22 16V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2zm-11-4 2.03 2.71L16 11l4 5H8l3-4zm-9 8V6h2v14h14v2H4a2 2 0 0 1-2-2z" clip-rule="evenodd"></path>
                                </svg>
                                <span aria-hidden="true">6</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            
              {/* <section id='section-2'>
                <div className='row mt-5'>
                  <div className='col-md-4'>
                     <h4 className='mt-3' style={{color:'black'}}>Itinerary Schedule</h4>
                  </div>
                  <div className='col-md-8'>
                      <button
                        className={`accordion  ${activeIndex === 0 ? 'active' : ''}`}
                        onClick={() => show(0)}
                      >
                        {Itinerarydetail[0].Itinerary_title}:{' '}
                        {Itinerarydetail[0].Itinerary_content}
                      </button>
                      <div className='panel'>
                        <p>{Itinerarydetail[0].Itinerary_city}</p>
                      </div>
                      {Itinerarydetailmore.map((item, index) => (
                        <div key={index}>
                          <button
                            className={`accordion  ${
                              activeIndex === index + 1 ? 'active' : ''
                            }`}
                            onClick={() => show(index + 1)}
                          >
                            {item.more_Itinerary_title} : {item.more_Itinerary_city}
                          </button>
                          <div class='panel'>
                            <p>{item.more_Itinerary_content}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </section> */}
              <section id='section-7'>
                 <Faqs />
              </section>
            </div>
          </div>
        </div>
      </Layout>
      <Modal isOpen={cancelModal} toggle={toggleModal2}>
        <ModalHeader toggle={toggleModal2}> Cancellation Policy</ModalHeader>
        <ModalBody>
          <div className='form-group'>
           <p>{tourDetail.tours.cancellation_policy}</p>
          </div>
        </ModalBody>
      </Modal>
      <div id="mybutton">
        <NavLink to='/book_package'>
          <button class="btn feedback  search-btn1 mt-2 detail-view-btn">Book Package</button>
        </NavLink>
      </div>
    </>
  )
}

export default ViewDeail
