import React, { useEffect, useState, useCallback } from 'react'
import DataTable from 'react-data-table-component'
import styled from 'styled-components'
import moment from 'moment'
function DashboardPage ({ invoiceListData }) {
  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold'
      }
    },
    rows: {
      style: {
        backgroundColor: '#f7f7fa'
      }
    }
  }

  const columns = [
    {
      name: 'Sr',
      selector: (row, index) => index + 1,
      sortable: true,
      cell: (row, index) => index + 1,
      width: '80px'
    },
    { name: 'Booking Type', selector: row => row.domain_name, sortable: true },
    {
      name: 'Invoice No',
      selector: row =>
        row.domain_name === 'Inovice Booking'
          ? `INV${row.generate_id}`
          : row.invoice_no,
      sortable: true
    },
    {
      name: 'Passenger Name',
      width: '150px',
      selector: row =>
        row.domain_name === 'Inovice Booking'
          ? row.b2b_Agent_Company_Name
          : row.lead_passenger,
      sortable: true
    },
    {
      name: 'Hotel Name',
      selector: row => row.domain_name, // This is needed for sorting
      sortable: true,
      width: '200px',
      cell: row => {
        try {
          if (row.domain_name === 'Hotel Booking') {
            const reservation = JSON.parse(row.reservation_response)
            return (
              <div>
                {reservation.hotel_details.hotel_name || ''}
                <br />
                {moment(reservation.hotel_details.checkIn).format(
                  'DD-MM-YYYY'
                )}{' '}
                {moment(reservation.hotel_details.checkOut).format(
                  'DD-MM-YYYY'
                )}
              </div>
            )
          } else if (row.domain_name === 'Transfer Booking') {
            const transfer = JSON.parse(row.transfer_data)
            return (
              <div>
                {transfer.pickup_City || ''}
                <br />
                Pckup Date : {moment(transfer.pickup_date).format('DD-MM-YYYY')}
              </div>
            )
          } else {
            return <div>{row.country}</div>
          }
        } catch (e) {
          return ''
        }
      }
    },
    {
      name: 'Total Amount',
      selector: row => row.exchange_price,
      sortable: true,
      cell: row =>
        `${
          row.domain_name === 'Inovice Booking'
            ? row.currency_symbol
            : row.exchange_currency
        } ${Number(
          row.domain_name === 'Inovice Booking'
            ? row.total_sale_price_Company
            : row.domain_name === 'Transfer Booking'
            ? row.transfer_total_price
            : row.exchange_price
        ).toFixed(2)}`
    },
    {
      name: 'Status',
      selector: row =>
        row.domain_name === 'Inovice Booking'
          ? row.confirm === null
            ? 'Tentative'
            : 'Confirm'
          : row.domain_name === 'Transfer Booking'
          ? row.booking_status
          : row.status,
      sortable: true
    }
  ]

  const filteredItems = invoiceListData.filter(item => {
    if (item.domain_name === 'Inovice Booking') {
      return (
        item.generate_id &&
        item.generate_id
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())
      )
    } else {
      return (
        (item.invoice_no &&
          item.invoice_no.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.lead_passenger &&
          item.lead_passenger.toLowerCase().includes(filterText.toLowerCase()))
      )
    }
  })
  function convertArrayOfObjectsToCSV (array) {
    if (!array || !array.length) {
      return null
    }

    const columnDelimiter = ','
    const lineDelimiter = '\n'
    const keys = Object.keys(array[0])

    let result = ''
    result += keys.join(columnDelimiter)
    result += lineDelimiter

    array.forEach(item => {
      let ctr = 0
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter

        result += item[key]

        ctr++
      })
      result += lineDelimiter
    })

    return result
  }
  const downloadCSV = useCallback(() => {
    if (invoiceListData.length === 0) {
      return
    }

    const csvData = invoiceListData.map((item, index) => {
      let content = ''
      let id = `item-${index}` // Create a dynamic id based on the index
      try {
        if (item.domain_name === 'Hotel Booking') {
          const reservation = JSON.parse(item.reservation_response)
          const hotelName = reservation.hotel_details.hotel_name || ''
          const checkIn = moment(reservation.hotel_details.checkIn).format(
            'DD-MM-YYYY'
          )
          const checkOut = moment(reservation.hotel_details.checkOut).format(
            'DD-MM-YYYY'
          )
          content = `${hotelName}<br />${checkIn} - ${checkOut}`
        } else if (item.domain_name === 'Transfer Booking') {
          const transfer = JSON.parse(item.transfer_data)
          const pickupCity = transfer.pickup_City || ''
          const pickupDate = moment(transfer.pickup_date).format('DD-MM-YYYY')
          content = `${pickupCity}<br />Pickup Date: ${pickupDate}`
        } else {
          content = item.country || ''
        }
      } catch (e) {
        content = ''
      }
      return {
        Sr: index + 1,
        'B2B Agent': item.domain_name,
        'Invoice No':
          item.domain_name === 'Inovice Booking'
            ? `INV${item.generate_id}`
            : item.invoice_no,
        'Passenger Name':
          item.domain_name === 'Inovice Booking'
            ? item.b2b_Agent_Company_Name
            : item.lead_passenger,
        'Hotel Name': content,
        'Total Amount': `${
          item.domain_name === 'Inovice Booking'
            ? item.currency_symbol
            : item.exchange_currency
        } ${Number(
          item.domain_name === 'Inovice Booking'
            ? item.total_sale_price_Company
            : item.domain_name === 'Transfer Booking'
            ? item.transfer_total_price
            : item.exchange_price
        ).toFixed(2)}`,
        Status:
          item.domain_name === 'Inovice Booking'
            ? item.confirm === null
              ? 'Tentative'
              : 'Confirm'
            : item.domain_name === 'Transfer Booking'
            ? item.booking_status
            : item.status
      }
    })

    const csv = convertArrayOfObjectsToCSV(csvData)
    if (csv == null) return

    const filename = 'Booking_List.csv'
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [invoiceListData])

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }
    const handlePrint = () => {
      const tableContent = `
       <h3 className='text-center'>Waqar International</h3>
        <table border="1" style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Booking Type</th>
              <th>Invoice No</th>
              <th>Passenger Name</th>
              <th>Hotel Name</th>
              <th>Total Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            ${filteredItems
              .map((item, index) => {
                let content = ''
                let id = `item-${index}` // Create a dynamic id based on the index
                try {
                  if (item.domain_name === 'Hotel Booking') {
                    const reservation = JSON.parse(item.reservation_response)
                    const hotelName = reservation.hotel_details.hotel_name || ''
                    const checkIn = moment(
                      reservation.hotel_details.checkIn
                    ).format('DD-MM-YYYY')
                    const checkOut = moment(
                      reservation.hotel_details.checkOut
                    ).format('DD-MM-YYYY')
                    content = `${hotelName}<br />${checkIn} - ${checkOut}`
                  } else if (item.domain_name === 'Transfer Booking') {
                    const transfer = JSON.parse(item.transfer_data)
                    const pickupCity = transfer.pickup_City || ''
                    const pickupDate = moment(transfer.pickup_date).format(
                      'DD-MM-YYYY'
                    )
                    content = `${pickupCity}<br />Pickup Date: ${pickupDate}`
                  } else {
                    content = item.country || ''
                  }
                } catch (e) {
                  content = ''
                }
                return `
                <tr>
                  <td>${index + 1}</td>
                  <td>${item.domain_name}</td>
                  <td>${
                    item.domain_name === 'Inovice Booking'
                      ? `INV${item.generate_id}`
                      : item.invoice_no
                  }</td>
                  <td>${
                    item.domain_name === 'Inovice Booking'
                      ? item.b2b_Agent_Company_Name
                      : item.lead_passenger
                  }</td>
                  <td id={id}>${content}</td>
                  <td>${
                    item.domain_name === 'Inovice Booking'
                      ? item.currency_symbol
                      : item.exchange_currency
                  } ${Number(
                  item.domain_name === 'Inovice Booking'
                    ? item.total_sale_price_Company
                    : item.domain_name === 'Transfer Booking'
                    ? item.transfer_total_price
                    : item.exchange_price
                ).toFixed(2)}</td>
                  <td>${
                    item.domain_name === 'Inovice Booking'
                      ? item.confirm === null
                        ? 'Tentative'
                        : 'Confirm'
                      : item.domain_name === 'Transfer Booking'
                      ? item.booking_status
                      : item.status
                  }</td>
                </tr>
              `
              })
              .join('')}
          </tbody>
        </table>
      `

      const newWindow = window.open()
      newWindow.document.write('<html><head><title>Print Table</title>')
      newWindow.document.write('</head><body>')
      newWindow.document.write(tableContent)
      newWindow.document.write('</body></html>')
      newWindow.document.close()
      newWindow.print()
    }
    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        handlePrint={handlePrint}
        downloadCSV={downloadCSV}
        downloadPDF={handlePrint}
      />
    )
  }, [filterText, resetPaginationToggle, downloadCSV])

  return (
    <>
      <div className='agent-head'>
        <h5>Total Bookings: {invoiceListData.length}</h5>
      </div>
      <div id='dataTable'>
        <DataTable
          title='My Bookings'
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          customStyles={customStyles}
        />
      </div>
    </>
  )
}

const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  handlePrint,
  downloadCSV,
  downloadPDF
}) => (
  <>
    <div>
      <button className='agent-filter-btn' onClick={handlePrint}>
        Print
      </button>
      <button className='agent-filter-btn' onClick={downloadCSV}>
        Download CSV
      </button>
      <button className='agent-filter-btn me-1' onClick={downloadPDF}>
        Download PDF
      </button>
    </div>
    <input
      id='search'
      type='text'
      placeholder='Search...'
      value={filterText}
      onChange={onFilter}
    />
    <button type='button' className='agent-filter-btn' onClick={onClear}>
      Clear
    </button>
  </>
)

export default DashboardPage
